import { Pill, darken, lighten } from '@mantine/core';

import { RiskTagDetailsDto } from '@/api-client';

interface RiskTagBadgeProps {
  tag: RiskTagDetailsDto;
  onRemove?: (tagId: string) => void;
  withRemoveButton?: boolean;
}

export const RiskTagBadge = ({ tag, onRemove, withRemoveButton = false }: RiskTagBadgeProps) => {
  const handleRemove = () => {
    if (!withRemoveButton) return;

    onRemove?.(tag.id);
  };

  return (
    <Pill
      key={tag.tag}
      c={darken(tag.color ?? 'blue', 0.1)}
      bg={lighten(tag.color ?? 'blue', 0.8)}
      size="xs"
      radius="sm"
      fw="bold"
      tt="capitalize"
      withRemoveButton={withRemoveButton}
      onRemove={handleRemove}
    >
      {tag.tag}
    </Pill>
  );
};
