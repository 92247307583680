import React from 'react';

import { IconBook } from '@tabler/icons-react';
import {
  Accordion,
  Anchor,
  Avatar,
  Card,
  Group,
  Progress,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { ProjectScopeModal } from '@/components/Projects/ProjectScopeModal/ProjectScopeModal';

import { apiClient } from '@/globals';

import type { KeyValuePair, Project, ProjectDetails } from '@/api-client';

interface ProjectOverviewCardProps {
  project: Project;
}

const Icon = ({ children }: React.PropsWithChildren) => (
  <ThemeIcon variant="transparent" color="gray" size="md">
    {children}
  </ThemeIcon>
);

const DetailsPanel = ({ project }: { project: Project }) => (
  <>
    <Accordion.Control>Details</Accordion.Control>

    <Accordion.Panel>
      <Stack gap="xs" py="xs">
        <ProjectScopeModal projectId={project.id}>
          <Anchor>
            <Group>
              <Icon>
                <IconBook stroke={1.5} size={18} />
              </Icon>
              <Text textfor="body">{project.name} scope</Text>
            </Group>
          </Anchor>
        </ProjectScopeModal>
        {/*Keep this section handy for later. Currently commented out until these pages are fleshed out.*/}
        {/*<Anchor component={Link} to={paths.proposals}>*/}
        {/*  <Group>*/}
        {/*    <Icon>*/}
        {/*      <IconWriting stroke={1.5} size={18} />*/}
        {/*    </Icon>*/}
        {/*    <Text textfor="body">{project.stats.proposals} Proposals</Text>*/}
        {/*  </Group>*/}
        {/*</Anchor>*/}

        {/*<Anchor component={Link} to={paths.contracts}>*/}
        {/*  <Group>*/}
        {/*    <Icon>*/}
        {/*      <IconBracketsContain stroke={1.5} size={18} />*/}
        {/*    </Icon>*/}
        {/*    <Text textfor="body">{project.stats.contracts} Contracts</Text>*/}
        {/*  </Group>*/}
        {/*</Anchor>*/}
      </Stack>
    </Accordion.Panel>
  </>
);

const TeamPanel = ({ team }: { team: ProjectDetails['team'] }) => (
  <>
    <Accordion.Control>Team</Accordion.Control>
    <Accordion.Panel>
      <Avatar.Group spacing="xs">
        {team.map((t) => (
          <Tooltip label={t.name} withArrow key={t.id}>
            <Avatar
              src={`https://www.gravatar.com/avatar/${t.email_sha256}?s=200&d=robohash`}
              alt={t.name}
            />
          </Tooltip>
        ))}
      </Avatar.Group>
    </Accordion.Panel>
  </>
);

const ResearchersPanel = ({ researchers }: { researchers: ProjectDetails['researchers'] }) => (
  <>
    <Accordion.Control>Researchers</Accordion.Control>
    <Accordion.Panel>
      {researchers.length === 0 ? (
        <Text textfor="body">No researchers</Text>
      ) : (
        <Avatar.Group spacing="xs">
          {researchers.map((r) => (
            <Tooltip label={r.name} withArrow key={r.id}>
              <Avatar
                src={`https://www.gravatar.com/avatar/${r.email_sha256}?s=200&d=robohash`}
                alt={r.name}
              />
            </Tooltip>
          ))}
        </Avatar.Group>
      )}
    </Accordion.Panel>
  </>
);

const MetricsPanel = ({
  risksCount,
  severities,
}: {
  risksCount: number;
  severities: ProjectDetails['severities'];
}) => {
  // get percentage of severity
  const getPercentage = (value: number) => (value * 100) / risksCount;

  const SEVERITIES_ORDER = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFORMATIONAL'];

  const orderedSeverities = SEVERITIES_ORDER.map((key) =>
    severities.find((s) => s.key === key)
  ).filter(Boolean) as KeyValuePair[];

  return (
    <>
      <Accordion.Control>Metrics</Accordion.Control>
      <Accordion.Panel>
        {risksCount === 0 ? (
          <Text textfor="body">No risk submitted</Text>
        ) : (
          <Stack gap="xs">
            <Text textfor="label">By Severity</Text>

            <Progress.Root size="xl">
              {orderedSeverities.map(({ key, value }) => (
                <Tooltip key={key} label={`${value} ${key}`}>
                  <Progress.Section
                    value={getPercentage(value)}
                    color={`var(--severity-color-${key.toLowerCase()})`}
                    tt="capitalize"
                  >
                    <Progress.Label>
                      {value} {key}
                    </Progress.Label>
                  </Progress.Section>
                </Tooltip>
              ))}
            </Progress.Root>
          </Stack>
        )}
      </Accordion.Panel>
    </>
  );
};

export function ProjectOverviewCard({ project }: ProjectOverviewCardProps) {
  const projectDetailsQuery = apiClient.queries.useProjectDetailsRetrieve(project.id);

  if (projectDetailsQuery.isLoading) {
    return <Card mih={100}>Loading...</Card>;
  }

  if (!projectDetailsQuery.isSuccess) {
    return null;
  }

  const projectDetails = projectDetailsQuery.data;

  return (
    <Card component="aside" withBorder>
      <Accordion multiple defaultValue={['Details', 'Team', 'Researchers', 'Metrics']}>
        <Accordion.Item value="Details">
          <DetailsPanel project={project} />
        </Accordion.Item>

        <Accordion.Item value="Team">
          <TeamPanel team={projectDetails.team} />
        </Accordion.Item>

        <Accordion.Item value="Researchers">
          <ResearchersPanel researchers={projectDetails.researchers} />
        </Accordion.Item>

        <Accordion.Item value="Metrics">
          <MetricsPanel risksCount={projectDetails.risks} severities={projectDetails.severities} />
        </Accordion.Item>
      </Accordion>
    </Card>
  );
}
