import { useEffect, useState } from 'react';

import { IconSwitchVertical } from '@tabler/icons-react';
import { Box, Button, Group, Pagination, Stack, Title } from '@mantine/core';
import { CreateComment } from '@/components/Risks/RiskDetails/CreateComment';
import { CommentCard } from './RiskCommentCard';

import { apiClient, queryClient } from '@/globals';
import { queryKeys } from '@/api-client';
import { useUserMe } from '@/hooks/use-user-me';

export const RiskCommentList = ({
  riskId,
  setCommentCount,
}: {
  riskId: string;
  setCommentCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = 20;
  const sortBy = 'createdAt';
  // get risk from API
  const riskResult = apiClient.queries.useRiskRetrieve(riskId, undefined);

  // get comment list from API
  const commentsResult = apiClient.queries.useCommentList(
    riskId,
    page,
    pageSize,
    sortBy,
    sortOrder
  );

  // get user data from API
  const userMe = useUserMe();

  const refreshCommentList = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeys.commentList(riskId, page, pageSize, sortBy, sortOrder),
    });
  };

  useEffect(() => {
    setTotalPages(commentsResult.data?.totalPages ?? 0);
    setCommentCount(commentsResult.data?.totalCount ?? 0);
  }, [commentsResult.data?.totalCount, commentsResult.data?.totalPages, setCommentCount]);

  // wait for risks to load
  if (riskResult.isError) {
    return <div>Failed to load risk</div>;
  }
  if (riskResult.isLoading) {
    return <div>Loading risk...</div>;
  }

  // wait for comments to load
  if (commentsResult.isError) {
    return <div>Failed to load comments</div>;
  }
  if (commentsResult.isLoading) {
    return <div>Loading comments...</div>;
  }

  const userEmailSha256 = userMe.email_sha256 || 'ABS123';

  return (
    <Box py="md">
      <Stack gap="md">
        <Stack gap="md">
          <Group justify="space-between" align="center">
            <Group gap="xs">
              <Title order={2} size="h6">
                Comments
              </Title>
            </Group>
            <Button
              variant="subtle"
              leftSection={<IconSwitchVertical stroke={1.5} size="12px" />}
              color="dark"
              mt={0}
              onClick={() => {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                setPage(1);
              }}
            >
              {sortOrder === 'desc' ? 'Newest First' : 'Oldest First'}
            </Button>
          </Group>

          <CreateComment
            userEmailSha256={userEmailSha256}
            riskId={riskId}
            refreshCommentList={refreshCommentList}
          />
          <Stack>
            {/* display list of comments */}
            {commentsResult.data?.results.map((c) => (
              <CommentCard
                riskId={riskId}
                key={c.id}
                comment={c}
                isAuthor={userMe.id === c.author.id}
                refreshCommentList={refreshCommentList}
              />
            ))}
            {/* show pagination */}
            <Pagination value={page} onChange={setPage} total={totalPages} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
