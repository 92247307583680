import React, { useEffect, useMemo } from 'react';
import {
  Anchor,
  Button,
  Card,
  Combobox,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconExternalLink, IconLinkPlus, IconUnlink } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { apiClient } from '@/globals';
import { LinkedRisk, Risk } from '@/api-client';
import classes from './RiskLink.module.css';
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal';

interface RiskLinkProps {
  // Define any props your component might need
  risk: Risk;
  projectId: string;
}

const RiskLink: React.FC<RiskLinkProps> = ({ risk, projectId }) => {
  const [selectedRiskId, setSelectedRiskId] = React.useState<string>('');
  const [filteredRisks, setFilteredRisks] = React.useState<Risk[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [linkedRisks, setLinkedRisks] = React.useState<LinkedRisk[]>([]);

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const { data: linkedRisksData, refetch: refetchLinkedRisks } =
    apiClient.queries.useRiskLinkedRisksList(risk.id);

  useEffect(() => {
    if (linkedRisksData !== undefined) {
      setLinkedRisks(linkedRisksData);
    }
  }, [linkedRisksData]);

  const { data, isLoading, isError } = apiClient.queries.useRiskList(
    projectId, // project
    undefined, // assignee
    undefined, // category
    undefined, // created_after
    undefined, // created_before
    undefined, // page
    undefined, // severity
    undefined, // status
    undefined // tag
    //   searchValue // title
  );

  useEffect(() => {
    if (data) {
      const _unlinkedRisks = data.results.filter(
        (_risk) =>
          !(
            linkedRisks.some((_linked_risk) => _linked_risk.id === _risk.id) || _risk.id === risk.id
          )
      );
      setFilteredRisks(_unlinkedRisks);
    }
  }, [data, linkedRisks, risk.id]);

  const riskData = useMemo(
    () => data?.results.find((_risk) => _risk.id === selectedRiskId),
    [data?.results, selectedRiskId]
  );

  const linkRisk = apiClient.mutations.useRiskLinkCreate(risk.id, {
    onError: (error: unknown) => {
      notifications.show({
        color: 'red',
        title: 'Risk link failed',
        message: (error as Error).message,
      });
    },
    onSuccess: async () => {
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk linked successfully',
      });
      await refetchLinkedRisks();
      setSelectedRiskId('');
      setSearchValue('');
    },
  });

  const unlinkRisk = apiClient.mutations.useRiskUnlinkCreate(risk.id, {
    onError: (error: unknown) => {
      notifications.show({
        color: 'red',
        title: 'Risk unlink failed',
        message: (error as Error).message,
      });
    },
    onSuccess: async () => {
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk unlinked successfully',
      });
      await refetchLinkedRisks();
    },
  });

  return (
    <Stack gap={4}>
      <Card withBorder radius="md" className={classes.container}>
        <Stack gap={8}>
          <Group justify="space-between" gap={12} mb={12} align="end">
            <Combobox
              onOptionSubmit={(optionValue) => {
                setSelectedRiskId(optionValue);
                combobox.closeDropdown();
                setSearchValue(
                  data?.results.find((_risk) => _risk.id === optionValue)?.title ?? ''
                );
              }}
              withinPortal
              store={combobox}
            >
              <Combobox.Target>
                <TextInput
                  flex={1}
                  size="sm"
                  placeholder="Search by title"
                  value={searchValue}
                  onChange={(event) => {
                    setSearchValue(event.currentTarget.value);
                    combobox.resetSelectedOption();
                    combobox.openDropdown();
                  }}
                  onClick={() => {
                    combobox.openDropdown();
                  }}
                  onFocus={() => {
                    combobox.openDropdown();
                  }}
                  onBlur={() => {
                    combobox.closeDropdown();
                  }}
                  rightSection={isLoading && <Loader size={18} />}
                />
              </Combobox.Target>

              <Combobox.Dropdown hidden={data === undefined}>
                <Combobox.Options>
                  {filteredRisks.slice(0, 9).map((_risk) => (
                    <Combobox.Option value={_risk.id} key={_risk.id}>
                      {_risk.title}
                    </Combobox.Option>
                  ))}
                  {(!filteredRisks.length || isError) && (
                    <Combobox.Empty>No results found</Combobox.Empty>
                  )}
                </Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
            <Button
              onClick={async () => {
                if (selectedRiskId && riskData) {
                  await linkRisk.mutateAsync({ linkedRiskId: riskData.id });
                }
              }}
              h={36}
              leftSection={<IconLinkPlus size={14} />}
            >
              Link Risk
            </Button>
          </Group>
          <Stack gap={4}>
            {linkedRisks.map((_linked_risk) => (
              <Group className={classes.linkedRisk} justify="space-between" key={_linked_risk.id}>
                <Group className={classes.title}>
                  <Anchor
                    component={Link}
                    target="_blank"
                    to={`/risk/${_linked_risk.id}`}
                    key={_linked_risk.id}
                  >
                    {_linked_risk.title}
                  </Anchor>
                  <IconExternalLink
                    className={classes.linkIcon}
                    size={15}
                    style={{ cursor: 'pointer' }}
                  />
                </Group>
                <ConfirmModal
                  buttonText="Unlink Risk"
                  onConfirm={async () => {
                    await unlinkRisk.mutateAsync({ linkedRiskId: _linked_risk.id });
                  }}
                  title="Confirm Risk Unlink"
                  description="Are you sure you want to unlink this risk?"
                  color="red"
                >
                  <Group className={classes.unlinkButton}>
                    <IconUnlink size={15} style={{ cursor: 'pointer' }} />
                    <Text color="gray" fz="12px">
                      Unlink
                    </Text>
                  </Group>
                </ConfirmModal>
              </Group>
            ))}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default RiskLink;
