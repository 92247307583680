import { json, LoaderFunction } from 'react-router';

import { queryKeys } from '@/api-client';
import { apiClient, queryClient } from '@/globals';

const riskRetrieveQuery = (riskId: string) => ({
  queryKey: queryKeys.riskRetrieve(riskId),
  queryFn: async () => apiClient.requests.riskRetrieve(riskId),
});

export const EditRiskLoader: LoaderFunction = async ({ params: { riskId } }) => {
  if (!riskId) throw new Error('Risk not found');

  const query = riskRetrieveQuery(riskId);

  const risk = await queryClient.fetchQuery({
    ...query,
    staleTime: 1000 * 60,
  });

  const userMe = await apiClient.requests.userMeRetrieve();

  if (userMe.user_type === 'RESEARCHER' && risk.locked) {
    throw json(
      { message: 'Risk is locked' },
      {
        status: 403,
      }
    );
  }

  return { risk };
};
