import { CategoryEnum, SeverityEnum } from '@/api-client';

export enum RISK_CATEGORY_LABEL_MAP {
  BROKEN_ACCESS_CONTROL = 'Broken Access Control',
  CRYPTOGRAPHIC_FAILURE = 'Cryptographic Failure',
  INJECTION = 'Injection',
  INSECURE_DESIGN = 'Insecure Design',
  SECURITY_MISCONFIGURATION = 'Security Misconfiguration',
  VULNERABLE_AND_OUTDATED_COMPONENTS = 'Vulnerable & Outdated Components',
  IDENTIFICATION_AND_AUTH_FAILURE = 'Identification and Auth Failures',
  DATA_INTEGRITY_FAILURE = 'Data Integrity Failures',
  SECURE_LOGGING_FAILURE = 'Secure Logging Failures',
  SERVER_SIDE_REQUEST_FORGERY = 'Server-Side Request Forgery',
}

export type RiskCategoryType = keyof typeof RISK_CATEGORY_LABEL_MAP;

export const RISK_CATEGORIES: CategoryEnum[] = [
  'BROKEN_ACCESS_CONTROL',
  'CRYPTOGRAPHIC_FAILURE',
  'INJECTION',
  'INSECURE_DESIGN',
  'SECURITY_MISCONFIGURATION',
  'VULNERABLE_AND_OUTDATED_COMPONENTS',
  'IDENTIFICATION_AND_AUTH_FAILURE',
  'DATA_INTEGRITY_FAILURE',
  'SECURE_LOGGING_FAILURE',
  'SERVER_SIDE_REQUEST_FORGERY',
];

export enum RISK_ENVIRONMENT_LABEL_MAP {
  WEB = 'Web',
  MOBILE = 'Mobile',
  API = 'API',
  NETWORK = 'Network',
}

export enum SEVERITY_LABEL_MAP {
  CRITICAL = 'Critical',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  INFORMATIONAL = 'Informational',
}

export const SEVERITIES: SeverityEnum[] = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFORMATIONAL'];
