import { Button, FileInput, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

import { IconPaperclip } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { apiClient } from '@/globals';
import { uploadToS3 } from '@/components/Attachments/requests';
import { AttachmentCreateDto, AttachmentDto } from '@/api-client';

interface AttachmentModalProps {
  callback: (attachment: AttachmentDto) => void;
}

const AttachmentModal = ({ callback }: AttachmentModalProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  const { data, error, isError, isSuccess, mutate } = apiClient.mutations.useAttachmentCreate();

  useEffect(() => {
    if (isError) {
      notifications.show({
        color: 'red',
        title: 'Failed to upload attachment',
        message: (error as AxiosError).message,
      });
      close();
    }
  }, [isError, error, close]);

  useEffect(() => {
    if (isSuccess) {
      callback(data);
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, close]);

  return (
    <>
      <Modal opened={opened} onClose={close} title="Upload" centered>
        {/* RISK: include list of allowed file-types */}

        <FileInput
          // placeholder="Select a file"
          onChange={(payload: File | null) => {
            if (payload === null) return;

            /* eslint-disable */
            uploadToS3(payload).then((psr): void => {
              if (psr === null) {
                // upload failed somehow, exit early
                return;
              }

              const attachment: AttachmentCreateDto = {
                key: psr.fields.key,
                filename: payload.name,
                contentType: payload.type,
                size: payload.size,
              };
              // create new attachment with mutation
              mutate(attachment);
            });
            /* eslint-disable */
          }}
        />
      </Modal>

      <div>
        <Button
          variant="light"
          onClick={open}
          leftSection={<IconPaperclip stroke={1.5} size={16} />}
        >
          Upload Attachment
        </Button>
      </div>
    </>
  );
};

export default AttachmentModal;
