import { Avatar, Box, Container, Divider, Flex, Group, Stack, Text, Tooltip } from '@mantine/core';
import RiskStatusSelect from '../RiskStatusSelect/RiskStatusSelect';
import { Gravatar, SeverityStatus } from '@/components/Common';
import RiskSLA from '../RiskSLA/RiskSLA';
import { RiskCategoryBadge } from '../RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '../RiskEnvironmentBadge/RiskEnvironmentBadge';
import { RiskTagsView } from '../RiskTags/RiskTagsView';
import { RiskTagsMenu } from '../RiskTags/RiskTagsMenu';
import { useUserType } from '@/hooks/use-user-type';
import DateDisplay from '@/components/Core/DateDisplay/DateDisplay';
import { NUMBER_OF_AVATAR_DISPLAYED } from '@/utils/constants';
import { apiClient } from '@/globals';
import { ProjectDto, RiskDto } from '@/api-client';
import If from '@/components/Common/If/If';
import { RiskUnlockButton } from '../RiskLock/RiskUnlockButton';
import RiskAssign from '../RiskAssign/RiskAssign';
import RiskLink from '../RiskLink/RiskLink';
import classes from './RiskSidebar.module.css';

const RiskSidebar = ({ risk, project }: { risk: RiskDto; project: ProjectDto }) => {
  const projectDetailsQuery = apiClient.queries.useProjectDetailsRetrieve(project.id);

  const userType = useUserType();
  const isResearcher = userType === 'RESEARCHER';
  if (!projectDetailsQuery.isSuccess && userType !== 'RESEARCHER') {
    return (
      <Container>
        <Box>Failed to load risk</Box>
      </Container>
    );
  }
  const projectDetails = projectDetailsQuery.data;

  return (
    <Stack justify="start" gap={16}>
      <Stack gap={4} className={classes.hideMobile}>
        <Flex>
          <RiskStatusSelect status={risk.status} riskId={risk.id} />
        </Flex>
      </Stack>
      <Stack gap={4} className={classes.hideMobile}>
        <Flex>
          <SeverityStatus variant="filled" severity={risk.severity} />
        </Flex>
      </Stack>
      <Stack gap={4}>
        <Text textfor="label">SLA</Text>
        <Box>
          <RiskSLA createdDate={risk.created} closedDate={risk.closed} />
        </Box>
      </Stack>
      {!isResearcher && (
        <Stack gap={4}>
          <Text textfor="label">Links</Text>
          <Box>
            <RiskLink risk={risk} projectId={project.id} />
          </Box>
        </Stack>
      )}
      <Stack gap={4} className={classes.hideMobile}>
        <Text textfor="label">Tags</Text>
        <Box>
          <Group>
            <RiskCategoryBadge category={risk.category} />
            <RiskEnvironmentsBadge environment={risk.environment} />

            {!isResearcher && (
              <>
                <Divider orientation="vertical" />
                <RiskTagsView riskId={risk.id} />
                <RiskTagsMenu riskId={risk.id} />
              </>
            )}
          </Group>
        </Box>
      </Stack>
      <If condition={userType === 'CLIENT'}>
        <Stack gap={4}>
          <Text textfor="label">Assignee</Text>
          <Flex>
            <RiskAssign projectId={project.id} risk={risk} />
          </Flex>
        </Stack>
      </If>
      <Stack gap={4}>
        <Text textfor="label">Disclosed</Text>
        <Text textfor="body">
          <DateDisplay showDate date={risk.created} />
        </Text>
      </Stack>
      <Stack gap={4}>
        <Text textfor="label">Project</Text>
        <Text textfor="body">{project.name}</Text>
      </Stack>
      <If condition={userType === 'CLIENT'}>
        <Stack gap={4}>
          <Text textfor="label">Team</Text>
          {projectDetails && (
            <Avatar.Group spacing="xs">
              {projectDetails.team.slice(0, NUMBER_OF_AVATAR_DISPLAYED).map((t) => (
                <Tooltip label={t.name} withArrow key={t.id}>
                  <Avatar
                    src={`https://www.gravatar.com/avatar/${t.email_sha256}?s=200&d=robohash`}
                    alt={t.name}
                  />
                </Tooltip>
              ))}
              {projectDetails.team.length > NUMBER_OF_AVATAR_DISPLAYED && (
                <Tooltip
                  label={`+${projectDetails.team.length - NUMBER_OF_AVATAR_DISPLAYED} more`}
                  withArrow
                >
                  <Avatar bg="charcoal.2" c="charcoal.6" size={34} my={4} variant="transparent">
                    +{projectDetails.team.length - NUMBER_OF_AVATAR_DISPLAYED}
                  </Avatar>
                </Tooltip>
              )}
            </Avatar.Group>
          )}
        </Stack>
      </If>
      <Stack gap={4}>
        <Text textfor="label">Disclosed by</Text>
        <Group>
          <Gravatar emailHash={risk.researcher.email_sha256} size={24} withoutTooltip />
          <Text textfor="body">{risk.researcher.name}</Text>
        </Group>
      </Stack>
      <If condition={userType !== 'RESEARCHER' && risk.locked}>
        <Stack gap={4}>
          <Group mb="sm" justify="end">
            <RiskUnlockButton risk={risk} />
            <Text textfor="body">Locked</Text>
          </Group>
        </Stack>
      </If>
    </Stack>
  );
};

export default RiskSidebar;
