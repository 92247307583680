import { useEffect, useMemo, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { apiClient, queryClient } from '@/globals';
import { queryKeys } from '@/api-client';
import { useCompany } from '@/hooks/use-company';

interface UseManageRiskTags {
  riskId: string;
}

export const useManageRiskTags = ({ riskId }: UseManageRiskTags) => {
  const company = useCompany();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const availableTagsListQuery = apiClient.queries.useCompanyTagsList(company.id);
  const availableTags = useMemo(
    () => availableTagsListQuery.data?.results ?? [],
    [availableTagsListQuery.data?.results]
  );

  const availableTagsMap = useMemo(
    () => Object.fromEntries(availableTags.map((tag) => [tag.id, tag])),
    [availableTags]
  );

  const selectedTagsQuery = apiClient.queries.useRiskTagList(riskId);
  const defaultSelectedTags = useMemo(
    () => selectedTagsQuery.data?.results ?? [],
    [selectedTagsQuery.data?.results]
  );

  const tagCreateMutation = apiClient.mutations.useTagCreate({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.companyTagsList(company.id) });
    },
    onError: () => {
      notifications.show({
        message: 'Failed to create tag',
        color: 'red',
      });
    },
  });

  const riskTagCreateMutation = apiClient.mutations.useRiskTagCreate({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.riskTagList(riskId) });
    },
  });

  useEffect(() => {
    setSelectedTags(defaultSelectedTags.map((tag) => tag.tag_details.id));
  }, [selectedTagsQuery.isFetched, defaultSelectedTags]);

  const handleTagSelect = (tagId: string) => {
    riskTagCreateMutation.mutate({
      tag: tagId,
      risk: riskId,
    });

    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tagId)
        ? prevSelectedTags.filter((selectedTagId) => selectedTagId !== tagId)
        : [...prevSelectedTags, tagId]
    );
  };

  const handleCreateNewTag = (tagValue: string) => {
    if (tagValue.length === 0) return;

    const newTag = { tag: tagValue, color: 'blue' };

    tagCreateMutation.mutate({
      company: company.id,
      tag: newTag.tag,
      color: newTag.color,
    });

    setSearchQuery('');
  };

  const handleTagRemove = async (tagId: string) => {
    const selectedTagId = defaultSelectedTags.find((tag) => tag.tag_details.id === tagId)?.id;

    if (!selectedTagId) {
      notifications.show({
        title: 'Tag not found',
        message: 'Please try again!',
        color: 'red',
      });
      return;
    }

    try {
      await apiClient.requests.riskTagDelete(riskId, tagId);
      setSelectedTags((prevSelectedTags) => prevSelectedTags.filter((id) => id !== tagId));
      await queryClient.invalidateQueries({ queryKey: queryKeys.riskTagList(riskId) });
    } catch {
      notifications.show({
        message: 'Failed to remove tag',
        color: 'red',
      });
    }
  };

  const handleDeleteTagOption = async (tagId: string) => {
    try {
      await apiClient.requests.tagDelete(tagId);
      await queryClient.invalidateQueries({ queryKey: queryKeys.companyTagsList(company.id) });
    } catch {
      notifications.show({
        message: 'Failed to delete tag',
        color: 'red',
      });
    }
  };

  const handleTagColorChange = async (tagId: string, newColor: string) => {
    try {
      await apiClient.requests.tagUpdate({ color: newColor }, tagId);
      await queryClient.invalidateQueries({ queryKey: queryKeys.companyTagsList(company.id) });
    } catch {
      notifications.show({
        message: 'Failed to update tag color',
        color: 'red',
      });
    }
  };

  return {
    selectedTags,
    searchQuery,
    setSearchQuery,
    availableTags,
    availableTagsMap,
    handleTagSelect,
    handleCreateNewTag,
    handleTagRemove,
    handleDeleteTagOption,
    handleTagColorChange,
  };
};
