import { apiClient } from '@/globals';
import { AttachmentCreateDto, AttachmentDto } from '@/api-client';

import { uploadToS3 } from './requests';

/**
 * Uploads a file to S3 and creates a new attachment object.
 */
export const createNewAttachment = (file: File): Promise<AttachmentDto> =>
  uploadToS3(file).then((psr) => {
    if (psr === null) {
      // upload failed somehow, exit early
      throw new Error('Invalid psr');
    }

    const attachment: AttachmentCreateDto = {
      key: psr.fields.key,
      filename: file.name,
      contentType: file.type,
      size: file.size,
    };
    // create new attachment with mutation
    return apiClient.requests.attachmentCreate(attachment);
  });
