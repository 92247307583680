import {
  Box,
  Combobox,
  Divider,
  Group,
  Pill,
  Text,
  ThemeIcon,
  Tooltip,
  useCombobox,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconUserPlus } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { Gravatar } from '@/components/Common';

import classes from './RiskAssign.module.css';
import { apiClient } from '@/globals';
import { RiskPartialUpdateDto, RiskDto, UserSummary, CompanyUserDto } from '@/api-client';
import { useCompany } from '@/hooks/use-company';

interface RiskAssignProps {
  projectId: string;
  risk: RiskDto;
}

interface partialUpdateRiskProps {
  id: string;
  payload: RiskPartialUpdateDto;
}

const RiskAssign = ({ projectId, risk }: RiskAssignProps) => {
  const [emailHash, setEmailHash] = useState<string>('');
  const [search, setSearch] = useState('');
  const [teamMembers, setTeamMembers] = useState<CompanyUserDto[] | undefined>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<CompanyUserDto>();

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const projectDetailsQuery = apiClient.queries.useProjectDetailsRetrieve(projectId);

  const projectDetails = projectDetailsQuery.data;
  const { users } = useCompany();
  useEffect(() => {
    setTeamMembers(users);
  }, [projectDetails, users]);

  const updateRiskMutation = useMutation({
    mutationFn: ({ id, payload }: partialUpdateRiskProps) =>
      apiClient.requests.riskPartialUpdate(payload, id),
    onSuccess: (data) => {
      if (data.assignee) {
        const member = teamMembers?.find((user) => user.id === data.assignee);
        setSelectedAssignee(member);
        setEmailHash(member?.email_sha256 ?? '');
      } else {
        setSelectedAssignee(undefined);
        setEmailHash('');
      }
    },
    retry: false,
  });

  useEffect(() => {
    if (risk.assignee) {
      const teamUser = teamMembers?.find((item) => item.id === risk.assignee);
      setSelectedAssignee(teamUser);
      setEmailHash(teamUser?.email_sha256 ?? '');
    }
  }, [risk, teamMembers]);

  const updateRisk = (id: string, assigneeId: string) => {
    updateRiskMutation.mutate({
      id,
      payload: {
        assignee: assigneeId,
      },
    });
  };

  const filteredTeamMembers =
    teamMembers
      ?.filter((teamMember) =>
        search ? teamMember.name.toLowerCase().includes(search.toLowerCase()) : true
      )
      .map((team) => (
        <Combobox.Option
          className={classes.optionItem}
          value={team.id}
          key={team.id}
          active
          py="sm"
        >
          <Group>
            <Box className={team.id === selectedAssignee?.id ? classes.ring : ''}>
              <Gravatar withoutTooltip emailHash={team.email_sha256} size={24} />
            </Box>
            <Text>{team.name}</Text>
          </Group>
        </Combobox.Option>
      )) ?? [];

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(e) => {
        updateRisk(risk.id, e);
        combobox.closeDropdown();
      }}
      withArrow={false}
      withinPortal
      position="bottom-start"
    >
      <Combobox.Target>
        <Box
          onClick={() => {
            combobox.toggleDropdown();
          }}
        >
          {!selectedAssignee ? (
            <Tooltip label="Assign" withArrow>
              <Pill
                color="blue"
                classNames={{
                  root: classes['mantine-Pill-root'],
                  remove: classes['mantine-Pill-remove'],
                  label: classes.label,
                }}
              >
                <ThemeIcon>
                  <IconUserPlus size={18} stroke={2} className={classes.icon} />
                </ThemeIcon>
              </Pill>
            </Tooltip>
          ) : (
            <Pill
              withRemoveButton
              onRemove={() => {
                updateRisk(risk.id, '');
              }}
              color="blue"
              classNames={{
                root: classes['assignee-pill-root'],
                remove: classes['mantine-Pill-remove'],
                label: classes.label,
              }}
            >
              <Gravatar
                tooltipPosition="bottom"
                name={selectedAssignee.name}
                emailHash={emailHash}
                size={28}
              />
            </Pill>
          )}
        </Box>
      </Combobox.Target>

      <Combobox.Dropdown miw={250} w={250} maw={350} px={0} py="xs">
        <Combobox.Search
          classNames={{
            input: classes.input,
            wrapper: classes.wrapper,
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
          }}
          placeholder="Search"
        />
        <Divider mb={16} />

        <Combobox.Options className={classes.optionsWrapper}>
          {filteredTeamMembers.length > 0 ? (
            filteredTeamMembers
          ) : (
            <Combobox.Empty>
              <Text textfor="caption">No team Member found.</Text>
            </Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default RiskAssign;
