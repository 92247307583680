import clsx from 'clsx';

import { Badge } from '@mantine/core';

import classes from './SeverityStatus.module.css';

import type { SeverityEnum } from '@/api-client';

interface Props {
  severity: SeverityEnum;
  variant?: 'filled' | 'light';
}

const SeverityStatus = ({ severity, variant = 'light' }: Props) => {
  const className: Record<SeverityEnum, string> = {
    INFORMATIONAL: classes.informational,
    CRITICAL: classes.critical,
    HIGH: classes.high,
    MEDIUM: classes.medium,
    LOW: classes.low,
  };

  const variantClassName = {
    filled: classes.filled,
    light: classes.light,
  };

  return (
    <Badge
      variant="dot"
      tt="capitalize"
      className={clsx(classes.root, className[severity], variantClassName[variant])}
    >
      {severity}
    </Badge>
  );
};

export default SeverityStatus;
