import { AxiosError } from 'axios';
import { Paper, Skeleton, Table } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '@/globals';

const ResearchersTable = () => {
  const navigate = useNavigate();

  const { isError, error, isLoading, isSuccess, data } = apiClient.queries.useResearcherList();

  if (isError) {
    const err = (error as AxiosError).message;
    return <Paper>Error: {err}</Paper>;
  }

  return (
    <Skeleton visible={isLoading}>
      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {isSuccess &&
            data.results.map((researcher) => (
              <Table.Tr
                key={researcher.id}
                onClick={() => {
                  navigate(`/researcher/${researcher.id}`);
                }}
              >
                <Table.Td>{researcher.name}</Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>
    </Skeleton>
  );
};

export default ResearchersTable;
