import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { DragDropContext } from '@hello-pangea/dnd';
import { useDebouncedValue } from '@mantine/hooks';

import { Box, Divider, Group, ScrollArea } from '@mantine/core';
import { getOnDragEnd } from '@/components/Risks/RiskList/getOnDragEnd';
import { KanbanColumn } from '@/components/Risks/RiskList/KanbanColumn';
import { PatchedRiskWrapper } from '@/components/Risks/RiskList/props';
import { RiskBoard } from '@/components/Risks/RiskList/RiskBoard';
import { KanbanRiskColumn } from '@/components/Risks/RiskList/utils';
import If from '@/components/Common/If/If';

import { useRiskFilters } from '@/store/risk-store';
import { debounceDuration } from '@/configuration';
import { apiClient, queryClient } from '@/globals';

import { type ProjectDto, queryKeys } from '@/api-client';
import { confettiStore } from '@/store/confetti-store';

interface KanbanLayoutProps {
  project: ProjectDto;
}

export const KanbanLayout = ({ project }: KanbanLayoutProps) => {
  const { categories, severities, dateRange, tags, hideRejected, assignee } = useRiskFilters();
  const { toggleShoot } = confettiStore();

  const [debCategories] = useDebouncedValue(categories, debounceDuration);
  const [debSeverities] = useDebouncedValue(severities, debounceDuration);
  const [debDateRange] = useDebouncedValue(dateRange, debounceDuration);
  const [debTags] = useDebouncedValue(tags, debounceDuration);
  const [debAssignee] = useDebouncedValue(assignee, debounceDuration);

  const date_range_from = debDateRange[0] ? moment(debDateRange[0]).format('L') : undefined;
  const date_range_to = debDateRange[1] ? moment(debDateRange[1]).format('L') : undefined;

  // TODO: pagination
  const { data, isLoading, isError } = apiClient.queries.useRiskList(
    project.id, // project
    debAssignee, // assignee
    debCategories, // category
    date_range_from, // created_after
    date_range_to, // created_before
    undefined, // page
    debSeverities, // severity
    undefined,
    debTags // tag
  );

  const [columns, setColumns] = useState(
    new RiskBoard(
      data?.results.filter((risk) => (hideRejected ? risk.status !== 'REJECTED' : true))
    ).items
  );

  useEffect(() => {
    const riskBoard = new RiskBoard(
      data?.results.filter((risk) => (hideRejected ? risk.status !== 'REJECTED' : true))
    );
    setColumns(riskBoard.items);
  }, [data?.results, hideRejected]);

  const riskMutation = useMutation({
    mutationFn: ({ riskId, payload }: PatchedRiskWrapper) =>
      apiClient.requests.riskPartialUpdate(payload, riskId),
    onSuccess: async (riskData) => {
      if (riskData.status === 'CLOSED') {
        toggleShoot(true);
      }

      await queryClient.invalidateQueries({
        queryKey: queryKeys.riskList(
          undefined, // assignee
          debCategories, // category
          date_range_from, // created_after
          date_range_to, // created_before
          undefined, // page
          project.id, // project
          debSeverities, // severity
          undefined,
          debTags // tag
        ),
      });
    },
    retry: false,
  });

  if (isError) {
    return <div>Error!</div>;
  }

  return (
    <Box>
      <Divider />
      <ScrollArea>
        <DragDropContext onDragEnd={getOnDragEnd(columns, setColumns, riskMutation.mutate)}>
          <If condition={!isLoading} fallback={<div>Loading...</div>}>
            <Group align="flex-start" wrap="nowrap">
              {KanbanRiskColumn.map(({ value, label }) => (
                <KanbanColumn
                  key={value}
                  status={value}
                  label={label}
                  risks={columns[value].list}
                  project={project}
                />
              ))}
            </Group>
          </If>
        </DragDropContext>
      </ScrollArea>
    </Box>
  );
};
