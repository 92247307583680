import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { apiClient } from '@/globals';

export const AcceptInvitationButton = ({
  inviteCode,
  onSuccess,
}: {
  inviteCode: string;
  onSuccess?: () => void;
}) => {
  const acceptExistingUserInvite = apiClient.mutations.useCompanyInvitationAcceptExistingUser({
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to accept invitation, Please try again',
        message: (error as Error).message,
      });
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });

  return (
    <Button
      onClick={() => {
        acceptExistingUserInvite.mutate({
          code: inviteCode,
        });
      }}
      loading={acceptExistingUserInvite.isPending}
      fullWidth
      size="md"
    >
      Accept Invitation
    </Button>
  );
};
