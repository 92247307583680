export const NUMBER_OF_AVATAR_DISPLAYED = 4;

export const userTypeTheme: Record<string, { badgeColor: string }> = {
  CLIENT: { badgeColor: '#1e64c7' },
  ADMIN: { badgeColor: '#A121C4' },
  RESEARCHER: { badgeColor: '#C32044' },
  TRIAGER: { badgeColor: '#A121C4' },
};

export const enum StorageKeys {
  JWT_TOKEN = '_authToken',
  REFRESH_TOKEN = '_refreshToken',
}
