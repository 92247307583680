import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

import { Center, Title, Text, Stack, rem, Alert, LoadingOverlay, Box, Group } from '@mantine/core';
import { Logo } from '@/components/Common';
import If from '@/components/Common/If/If';
import { ExistingUserInviteForm } from '@/components/Invitations/ExistingUserInviteForm/ExistingUserInviteForm';
import { NewUserInviteForm } from '@/components/Invitations/NewUserInviteForm/NewUserInviteForm';
import { AcceptInvitationButton } from '@/components/Invitations/AcceptInvitationButton/AcceptInvitationButton';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import { useQueryParams } from '@/hooks/use-query-params';
import { apiClient } from '@/globals';
import classes from '../../layouts/AuthLayout/AuthLayout.module.css';

const InvalidOrExpiredToken = () => (
  <Center h="100%" mih="100vh">
    <Alert title="Invalid or Expired Token" color="red" mx="md" w={rem(400)}>
      The Invitation link you used is invalid or has expired. Please ask for a new invitation to get
      a fresh link sent to your email.
    </Alert>
  </Center>
);

export function InvitationPage() {
  const navigate = useNavigate();

  const params = useQueryParams();
  const inviteCode = params.get('invite-code');

  const validateInvitation = apiClient.mutations.useCompanyInvitationValidate();

  const userMe = apiClient.queries.useUserMeRetrieve({
    enabled: validateInvitation.isSuccess,
    retry: false,
  });

  useEffect(() => {
    if (inviteCode) {
      validateInvitation.mutate({ code: inviteCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode]);

  useTrackPageView('invitation');

  if (validateInvitation.isPending || userMe.isLoading) {
    return <LoadingOverlay visible overlayProps={{ blur: 2 }} />;
  }

  if (validateInvitation.isError || !inviteCode) {
    return <InvalidOrExpiredToken />;
  }

  // Retrieve Invitation Data
  const invitationData = validateInvitation.data;
  const companyName = invitationData?.companyName;

  // Check if user is signed with the same invitation email.
  // Then check if the user exists.
  const isSignedIn = userMe.data?.email && userMe.data.email === invitationData?.email;
  const isExistingUser = invitationData?.existingUser;

  const onSuccess = () => {
    notifications.show({
      color: 'green',
      title: 'Invitation Accepted',
      message: `You have successfully joined ${companyName}`,
    });

    navigate('/');
  };

  return (
    <div className={classes.wrapper}>
      <Box className={classes.nav}>
        <Group justify="center">
          <Logo size="large" />
        </Group>
      </Box>
      {/* <Paper className={classes.formWrapper} shadow="sm" p="xl"> */}
      <Box className={classes.formWrapper}>
        <Stack w={rem(400)}>
          <Box>
            <Box mb="md">
              <Title ta="center" order={1} textfor="h3">
                You&apos;ve been invited to join
              </Title>
              <Title ta="center" order={2} textfor="h2">
                {companyName}
              </Title>
            </Box>

            <If condition={!isSignedIn}>
              <Group justify="center" mb={32}>
                <Text>Please sign in/up to accept the invite</Text>
              </Group>
            </If>

            <div>
              {isSignedIn ? (
                <AcceptInvitationButton inviteCode={inviteCode} onSuccess={onSuccess} />
              ) : isExistingUser ? (
                <ExistingUserInviteForm inviteCode={inviteCode} onSuccess={onSuccess} />
              ) : (
                <NewUserInviteForm
                  isSignedIn={Boolean(isSignedIn)}
                  inviteCode={inviteCode}
                  onSuccess={onSuccess}
                />
              )}
            </div>
          </Box>
        </Stack>
        {/* </Paper> */}
      </Box>
    </div>
  );
}
