import { Risk, RiskStatusEnum, SeverityEnum } from '@/api-client';

interface RiskCounts {
    newRiskCount: number;
    inProgressRiskCount: number;
    closedRiskCount: number;
    criticalRiskCount: number;
    highRiskCount: number;
    mediumRiskCount: number;
    lowRiskCount: number;
    informationalRiskCount: number;
}

export const getRiskCount = (risksData: Risk[]): RiskCounts => {
    const data = Array.isArray(risksData) ? risksData : [];

    return data.reduce((acc: RiskCounts, risk: {
        status?: RiskStatusEnum;
        severity?: SeverityEnum;
    }) => {
        const result: RiskCounts = { ...acc };

        switch (risk.status) {
            case 'NEW': {
                result.newRiskCount += 1;
                break;
            }
            case 'IN_PROGRESS': {
                result.inProgressRiskCount += 1;
                break;
            }
            case 'CLOSED': {
                result.closedRiskCount += 1;
                break;
            }
        }

        switch (risk.severity) {
            case 'critical': {
                result.criticalRiskCount += 1;
                break;
            }
            case 'high': {
                result.highRiskCount += 1;
                break;
            }
            case 'medium': {
                result.mediumRiskCount += 1;
                break;
            }
            case 'low': {
                result.lowRiskCount += 1;
                break;
            }
            case 'informational': {
                result.informationalRiskCount += 1;
                break;
            }
        }

        return result;
    }, {
        newRiskCount: 0,
        inProgressRiskCount: 0,
        closedRiskCount: 0,
        criticalRiskCount: 0,
        highRiskCount: 0,
        mediumRiskCount: 0,
        lowRiskCount: 0,
        informationalRiskCount: 0,
    });
};
