import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import { initialize } from '@/api-client';
import { StorageKeys } from './utils/constants';

const csrf = {
  cookieName: 'csrftoken',
  headerName: 'X-CSRFTOKEN',
};

export const pagination = {
  messagePageSize: 20,
  defaultPageSize: 20,
};

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(StorageKeys.JWT_TOKEN);
    if (token && token !== 'undefined') {
      // eslint-disable-next-line
      config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
    }
    return config;
  },
  (error: Error) => Promise.reject(error)
);

// refresh token logic
interface RetryQueueItem {
  resolve: (value?: unknown) => void;
  reject: (error?: unknown) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest
      //    &&  !originalRequest._retry
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshToken = localStorage.getItem(StorageKeys.REFRESH_TOKEN);
          if (refreshToken) {
            const response = await axios.post(
              `${import.meta.env.VITE_API_URL}/auth/refresh_token`,
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                refreshToken: JSON.parse(refreshToken),
              }
            );
            if (response.status === 201) {
              localStorage.setItem(
                StorageKeys.JWT_TOKEN,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                JSON.stringify(response.data.accessToken)
              );
              localStorage.setItem(
                StorageKeys.REFRESH_TOKEN,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                JSON.stringify(response.data.refreshToken)
              );

              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;

              refreshAndRetryQueue.forEach((queueItem) => {
                axiosInstance
                  .request(queueItem.config)
                  .then((_response) => {
                    queueItem.resolve(_response);
                  })
                  .catch((_error) => {
                    queueItem.reject(_error);
                  });
              });
              refreshAndRetryQueue.length = 0;
              // eslint-disable-next-line @typescript-eslint/return-await
              return axiosInstance(originalRequest);
            }
          } else {
            isRefreshing = false;
            if (
              !window.location.pathname.includes('/auth') &&
              !window.location.pathname.includes('/invitation')
            ) {
              const intendedUrl = window.location.pathname;
              window.location.href = `/auth/sign-in?intended=${intendedUrl}`;
            }
          }
        } catch (_error) {
          // eslint-disable-next-line no-console
          //console.log('Refresh token failed', _error);
          localStorage.removeItem(StorageKeys.JWT_TOKEN);
          localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
          window.location.href = '/auth/sign-in';
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ resolve, reject, config: originalRequest });
        });
      }
    }
    return Promise.reject(error);
  }
);

export const apiClient = initialize(axiosInstance);

export const queryClient = new QueryClient();
