import { useEffect } from 'react';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { IconCircleX, IconLock, IconMail, IconUser } from '@tabler/icons-react';
import { Alert, Button, PasswordInput, Stack, TextInput, ThemeIcon } from '@mantine/core';
import If from '@/components/Common/If/If';

import { registerSchema } from '@/lib/validations';
import { useSignOut } from '@/hooks/use-sign-out';
import { apiClient } from '@/globals';

export function NewUserInviteForm({
  isSignedIn,
  onSuccess,
  inviteCode,
}: {
  isSignedIn: boolean;
  inviteCode: string;
  onSuccess?: () => void;
}) {
  const signOut = useSignOut();

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },

    validate: zodResolver(registerSchema),
  });

  const acceptNewUserInvite = apiClient.mutations.useCompanyInvitationAcceptNewUser({
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Sign-up failed',
        message: (error as Error).message,
      });
    },
    onSuccess: () => {
      form.reset();
      onSuccess?.();
    },
  });

  useEffect(() => {
    if (isSignedIn) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = acceptNewUserInvite.isPending;

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        acceptNewUserInvite.mutate({
          name: values.name,
          email: values.email,
          password: values.password,
          passwordConfirmation: values.confirmPassword,
          code: inviteCode,
        });
      })}
    >
      <Stack gap="md">
        <TextInput
          label="Name"
          placeholder="Johnny Appleseed"
          disabled={isLoading}
          required
          leftSection={
            <ThemeIcon>
              <IconUser strokeWidth="inherit" />
            </ThemeIcon>
          }
          {...form.getInputProps('name')}
        />

        <TextInput
          label="Email"
          placeholder="johnny@discloze.com"
          disabled={isLoading}
          required
          leftSection={
            <ThemeIcon>
              <IconMail strokeWidth="inherit" />
            </ThemeIcon>
          }
          {...form.getInputProps('email')}
        />

        <PasswordInput
          label="Password"
          placeholder="Password"
          required
          disabled={isLoading}
          {...form.getInputProps('password')}
          leftSection={
            <ThemeIcon>
              <IconLock strokeWidth="inherit" />
            </ThemeIcon>
          }
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm Password"
          required
          disabled={isLoading}
          leftSection={
            <ThemeIcon>
              <IconLock strokeWidth="inherit" />
            </ThemeIcon>
          }
          {...form.getInputProps('confirmPassword')}
        />
      </Stack>

      <If condition={acceptNewUserInvite.isError}>
        <Alert color="red" icon={<IconCircleX />} title="Sign-in failed" variant="light" mt="sm">
          Sign-Up failed
        </Alert>
      </If>

      <Button type="submit" fullWidth loading={isLoading} size="md" mt="xl">
        Sign Up and Accept Invitation
      </Button>
    </form>
  );
}
