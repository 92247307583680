import { queryKeys } from '@/api-client';
import { apiClient } from '@/globals';

export const contractQuery = (id: string) => ({
  queryKey: queryKeys.contractRetrieve(id),
  queryFn: async () => apiClient.requests.contractRetrieve(id),
});

export const conversationListQuery = (contractId: string) => ({
  queryKey: queryKeys.conversationList(contractId, 1),
  queryFn: async () => apiClient.requests.conversationList(contractId, 1),
});

export const conversationQuery = (conversationId: string) => ({
  queryKey: queryKeys.conversationRetrieve(conversationId),
  queryFn: async () => apiClient.requests.conversationRetrieve(conversationId),
});

export const userMeQuery = () => ({
  queryKey: queryKeys.userMeRetrieve(),
  queryFn: async () => apiClient.requests.userMeRetrieve(),
});

export const messageListQuery = (conversationId: string) => ({
  queryKey: queryKeys.messageList(conversationId, 0),
  queryFn: async () => apiClient.requests.messageList(conversationId, 1),
});

export const projectQuery = (id: string) => ({
  queryKey: queryKeys.projectRetrieve(id),
  queryFn: async () => apiClient.requests.projectRetrieve(id),
});

export const contractListQuery = (projectId?: string) => ({
  queryKey: queryKeys.contractList(projectId),
  queryFn: async () => apiClient.requests.contractList(projectId),
});

export const proposalListQuery = (proposalId?: string) => ({
  queryKey: queryKeys.proposalList(proposalId),
  queryFn: async () => apiClient.requests.proposalList(proposalId),
});

export const riskQuery = (id: string) => ({
  queryKey: queryKeys.riskRetrieve(id),
  queryFn: async () => apiClient.requests.riskRetrieve(id),
});

export const riskCommentsQuery = (id: string, page = 1) => ({
  queryKey: queryKeys.riskCommentsList(id, undefined, undefined, undefined, page),
  queryFn: async () =>
    apiClient.requests.riskCommentsList(id, undefined, undefined, undefined, page),
});

export const riskListQuery = (projectId?: string) => ({
  queryKey: queryKeys.riskList(undefined, undefined, undefined, 1, projectId),
  queryFn: async () => apiClient.requests.riskList(undefined, undefined, undefined, 1, projectId),
});

export const companyListQuery = (page = 1) => ({
  queryKey: queryKeys.companyList(page),
  queryFn: async () => apiClient.requests.companyList(page),
});
