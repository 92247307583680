import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { DateValue } from '@mantine/dates';
import type { CategoryEnum, SeverityEnum } from '@/api-client';

export type DateRange = [DateValue, DateValue];

export type Status = 'CLOSED' | 'IN_PROGRESS' | 'NEW' | 'REJECTED'; //| 'ALL';

interface UseRiskFilters {
  categories: CategoryEnum[];
  severities: SeverityEnum[];
  dateRange: DateRange;
  tags: string[];
  status?: Status;
  assignee: string[];
  hideRejected?: boolean;
  setStatus: (status: Status) => void;
  setAssignee: (assignee: string[]) => void;
  setCategories: (category: CategoryEnum[]) => void;
  setSeverities: (severity: SeverityEnum[]) => void;
  setDateRange: (dateRange: DateRange) => void;
  setTags: (tags: string[]) => void;
  toggleHideRejected: () => void;
  removeCategory: (category: CategoryEnum) => void;
  removeSeverity: (category: SeverityEnum) => void;
  removeDateRange: (range: 'from' | 'to') => void;
  removeTag: (tagId: string) => void;
  removeAll: (type?: keyof typeof initialFilters) => void;
  removeAssignee: (assignee: string) => void;
}

const initialFilters = {
  severities: [],
  categories: [],
  dateRange: [null, null] as DateRange,
  tags: [],
  status: [],
  assignee: [],
  hideRejected: false,
};

export const useRiskFilters = create(
  persist<UseRiskFilters>(
    (set) => ({
      categories: initialFilters.categories,
      severities: initialFilters.severities,
      dateRange: initialFilters.dateRange,
      tags: initialFilters.tags,
      status: initialFilters.status,
      assignee: initialFilters.assignee,
      hideRejected: initialFilters.hideRejected,

      toggleHideRejected: () => {
        set((state) => ({ hideRejected: !state.hideRejected }));
      },

      setSeverities: (severities) => {
        set({ severities });
      },
      setCategories: (categories) => {
        set({
          categories,
        });
      },
      setDateRange: (dateRange) => {
        set({ dateRange });
      },
      setTags: (tags) => {
        set({ tags });
      },
      setStatus: (status) => {
        set({ status });
      },

      setAssignee(assignee) {
        set({ assignee });
      },

      removeSeverity: (severity) => {
        set((state) => ({ severities: state.severities.filter((s) => s !== severity) }));
      },
      removeCategory: (category) => {
        set((state) => ({ categories: state.categories.filter((s) => s !== category) }));
      },
      removeDateRange: (range) => {
        set((state) => ({
          dateRange: range === 'from' ? [null, state.dateRange[1]] : [state.dateRange[0], null],
        }));
      },
      removeStatus: (status) => {
        set((state) => ({ status: state.status.filter((s) => s !== status) }));
      },
      removeTag: (tagId) => {
        set((state) => ({ tags: state.tags.filter((s) => s !== tagId) }));
      },
      removeAssignee: (assignee) => {
        set((state) => ({ tags: state.tags.filter((s) => s !== assignee) }));
      },
      
      removeAll: () => {
        set({
          status: undefined,
          categories: [],
          severities: [],
          dateRange: [null, null],
          tags: [],
          assignee: [],
          hideRejected: false,
        });
      },
    }),
    {
      name: 'risk-filters',
    }
  )
);
