import { UserTypeEnum } from './api-client';

export const debounceDuration = 500; // 500ms

export const appHomePaths: Record<UserTypeEnum, string> = {
  CLIENT: '/projects',
  RESEARCHER: '/projects',
  TRIAGER: '/projects',
  ADMIN: '/projects',
};

export const paths = {
  // appHome: {
  //   client: '/projects',
  //   partner: '/projects',
  //   researcher: '/projects',
  //   triager: '/projects',
  // },
  appHome: appHomePaths,
  signIn: '/auth/sign-in',
  requestResetPassword: '/auth/reset-password',
  resetPassword: '/reset-password',
  invitation: '/invitation',
  contracts: '/contracts',
  proposals: '/proposals',
  proposal: (proposal_id: string) => `/proposals/${proposal_id}` as const,
  contract: (contract_id: string) => `/contract/${contract_id}` as const,
  projects: '/projects',
  project: (project_id: string) => `/project/${project_id}` as const,
  risk: (risk_id: string) => `/risk/${risk_id}` as const,
  newRisk: (project_id: string) => `/project/${project_id}/new-risk` as const,
  researchers: '/researchers',
  researcher: (researcher_id: string) => `/researcher/${researcher_id}` as const,
  editRisk: (risk_id: string) => `/risk/${risk_id}/edit` as const,
  settings: {
    base: '/settings',
    mySettings: '/settings/my-settings',
    companySettings: (company_id: string) => `/settings/company-settings/${company_id}` as const,
    manageUsers: (company_id: string) => `/settings/manage-users/${company_id}` as const,
  },
} as const;
