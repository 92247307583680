import { Badge, BadgeProps } from '@mantine/core';

import classes from './RiskStatusBadge.module.css';

import type { RiskStatusEnum } from '@/api-client';

const riskStatusOptions: Record<RiskStatusEnum, string> = {
  DRAFT: 'Draft',
  REVIEW: 'Review',
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  CLOSED: 'Closed',
  REJECTED: 'Rejected',
  DUPLICATE: 'Duplicate',
};

interface RiskStatusBadgeProps {
  status: RiskStatusEnum;
  size?: BadgeProps['size'];
}

export const RiskStatusBadge = ({ status, size }: RiskStatusBadgeProps) => (
  <Badge radius="sm" className={classes[status.toLowerCase()]} size={size}>
    {riskStatusOptions[status]}
  </Badge>
);
