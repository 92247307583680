import React from 'react';
import * as z from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import { Box, Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core';

import { apiClient, queryClient } from '@/globals';
import { queryKeys } from '@/api-client';

const projectTitleSchema = z.object({
  name: z
    .string()
    .min(3, 'Title must be at least 3 characters')
    .max(50, 'Title must be less than 50 characters'),
});

interface ProjectTitleEditModalProps {
  children: JSX.Element;
  projectId: string;
  currentTitle?: string;
  onSuccess?: (newTitle: string) => void;
}

type ProjectTitleFormValues = z.infer<typeof projectTitleSchema>;

export const ProjectTitleEditModal = ({
  children,
  projectId,
  currentTitle,
  onSuccess,
}: ProjectTitleEditModalProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      name: currentTitle ?? '',
    },
    validate: zodResolver(projectTitleSchema),
  });

  const handleClose = () => {
    form.reset();
    close();
  };

  const projectUpdateMutation = apiClient.mutations.useProjectPartialUpdate(projectId, {
    onSuccess: async () => {
      close();
      await queryClient.invalidateQueries({ queryKey: queryKeys.projectList() });
      onSuccess?.(form.getValues().name);
    },
  });

  const handleSubmit = (formValues: ProjectTitleFormValues) => {
    projectUpdateMutation.mutate({
      name: formValues.name,
    });
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal opened={opened} onClose={handleClose} title="Edit Project Title" centered>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack gap="md">
            <Box>
              <Text textfor="paragraph" mb="xs">
                Change the project title
              </Text>
              <TextInput label="Title" {...form.getInputProps('name')} />
            </Box>

            <Group justify="flex-end">
              <Button variant="subtle" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                loading={projectUpdateMutation.isPending}
                disabled={!form.isValid()}
              >
                Save
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
