import { useEffect, useRef, useState } from 'react';
import { Button, Center, ScrollArea, Space, Stack, Textarea } from '@mantine/core';
import { AxiosError } from 'axios';
import { notifications } from '@mantine/notifications';
import { Attachment, Conversation, Message, User } from '@/api-client';
import AttachmentModal from '@/components/Attachments/AttachmentModal/AttachmentModal';
import MessageBox from '@/components/Common/MessageList/MessageBox';
import { UseInfiniteMessages, UseSendMessage } from './hooks';

interface Props {
  conversation: Conversation;
  userMe: User;
}

export const MessageList = ({ conversation, userMe }: Props) => {
  const [called, setCalled] = useState(false);
  const viewport = useRef<HTMLDivElement>(null);
  const infiniteMessages = UseInfiniteMessages(conversation.id);
  const sendMessage = UseSendMessage(viewport);

  let firstId: string | undefined;
  try {
    firstId = infiniteMessages.data?.pages[0].results[0].id;
  } catch (e) {
    firstId = undefined;
  }

  // scroll to the bottom of viewport one time after messages are loaded
  useEffect(() => {
    if (called || infiniteMessages.data === undefined) return;
    setCalled(true);
    if (viewport.current) {
      viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'auto' });
    }
  }, [firstId, called, infiniteMessages.data]);

  const LoadPreviousMessages = () => (
    <Center>
      <Button
        variant="filled"
        onClick={() => {
          infiniteMessages.fetchNextPage().catch((reason: AxiosError) => {
            notifications.show({
              color: 'red',
              title: 'Failed to load previous messages',
              message: reason.message,
            });
          });
        }}
      >
        Load previous messages
      </Button>
    </Center>
  );

  function* reversedMessages() {
    // eslint-disable-next-line no-restricted-syntax
    for (const page of infiniteMessages.data?.pages ?? []) {
      // eslint-disable-next-line no-restricted-syntax
      for (const msg of page.results.slice(0).reverse() ?? []) {
        yield msg;
      }
    }
  }

  return (
    <>
      <ScrollArea.Autosize mah={500} mih={200} viewportRef={viewport} mx="auto" w="100%">
        <Stack>
          {/* load previous messages button */}
          {infiniteMessages.hasNextPage && <LoadPreviousMessages />}

          {/* list of MessageBox */}
          {Array.from(reversedMessages()).map((m: Message) => (
            <MessageBox key={m.id} message={m} currentUser={userMe} />
          ))}
        </Stack>
      </ScrollArea.Autosize>
      <Space h="lg" />
      <Textarea
        placeholder="Type message here..."
        onKeyDown={(e) => {
          if (e.key !== 'Enter') return;
          e.preventDefault();
          sendMessage(conversation.id, e.currentTarget.value);
          // clear message from textarea
          e.currentTarget.value = '';
          setCalled(false);
        }}
      />
      <AttachmentModal
        callback={(attachment: Attachment) => {
          sendMessage(conversation.id, `attachment:${attachment.id}`, attachment.id);
        }}
      />
    </>
  );
};
