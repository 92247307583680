import React from 'react';
import moment from 'moment';
import { useDebouncedValue } from '@mantine/hooks';

import RiskCard from '@/components/Risks/RiskCard/RiskCard';

import { useRiskFilters } from '@/store/risk-store';
import { apiClient } from '@/globals';

import type { ProjectDto } from '@/api-client';
import { debounceDuration } from '@/configuration';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';

interface RiskCardsProps {
  project: ProjectDto;
  page: number;
}

export const RiskCards = React.memo(({ project, page }: RiskCardsProps) => {
  const { categories, severities, dateRange, tags, status, hideRejected, assignee } =
    useRiskFilters();

  const [debCategories] = useDebouncedValue(categories, debounceDuration);
  const [debSeverities] = useDebouncedValue(severities, debounceDuration);
  const [debDateRange] = useDebouncedValue(dateRange, debounceDuration);
  const [debTags] = useDebouncedValue(tags, debounceDuration);
  const [debStatuses] = useDebouncedValue(status, debounceDuration);

  const date_range_from = debDateRange[0] ? moment(debDateRange[0]).format('L') : undefined;
  const date_range_to = debDateRange[1] ? moment(debDateRange[1]).format('L') : undefined;
  const [debAssignee] = useDebouncedValue(assignee, debounceDuration);

  function filterStatus(status) {
    if (typeof status === 'string' && status.toUpperCase() === 'ALL') {
      return undefined;
    }
    if (Array.isArray(status) && status.includes('ALL')) {
      return undefined;
    }
    return status;
  }

  // TODO: pagination
  const { data, isLoading, isError } = apiClient.queries.useRiskList(
    project.id, // project
    debAssignee, // assignee
    debCategories, // category
    date_range_from, // created_after
    date_range_to, // created_before
    page, // page
    debSeverities, // severity
    //status === 'ALL' ? undefined : status, // status
    status,
    //filterStatus(status), // status
    debTags // tag
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error!</div>;
  }

  return data?.results
    .filter((risk) => (hideRejected ? risk.status !== 'REJECTED' : true))
    .map((risk) => <RiskCard projectId={project.id} key={risk.id} layout="Row" risk={risk} />);
});

RiskCards.displayName = 'RiskCards';
