import type { AxiosInstance, AxiosRequestConfig } from "axios";
import { useQuery, useMutation, useQueryClient, type QueryClient, type UseMutationOptions, type UseQueryOptions, type MutationFunction, type UseMutationResult, type UseQueryResult } from "@tanstack/react-query";
export type LoginDto = {
    email: string;
    password: string;
};
export type LoginResponseDto = {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
};
export type PasswordResetRequestDto = {
    email: string;
};
export type PasswordResetValidateRequestDto = {
    code: string;
};
export type PasswordResetConfirmRequestDto = {
    code: string;
    password: string;
};
export type TokenRefreshRequestDto = {
    refreshToken: string;
};
export type TokenRefreshResponseDto = {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
};
export type UserTypeEnum = "CLIENT" | "RESEARCHER" | "TRIAGER" | "ADMIN";
export type UserDto = {
    id: string;
    name: string;
    email: string;
    user_type: UserTypeEnum;
    email_sha256: string;
    is_active: boolean;
};
export type UserUpdateDto = {
    name?: string;
    email?: string;
    userType?: string;
};
export type CompanyUserDto = {
    id: string;
    name: string;
    email: string;
    userType: string;
    email_sha256: string;
    isActive: boolean;
};
export type CompanyProjectDto = {
    id: string;
    name: string;
    description: string;
    status: string;
    skills: string[];
    payment: string;
    budget: number;
    logoUrl: string;
    created: string;
    updated: string;
};
export type CompanyDto = {
    id: string;
    name: string;
    created: string;
    updated: string;
    users: CompanyUserDto[];
    triagers: CompanyUserDto[];
    projects: CompanyProjectDto[];
};
export type CompanyTagDto = {
    id: string;
    tag: string;
    color: string;
    created: string;
    updated: string;
    companyId: string;
};
export type CompanyItemDto = {
    id: string;
    name: string;
    created: string;
};
export type CompaniesFullListDto = {
    totalCount: number;
    totalPages: number;
    page: number;
    nextPage: number;
    results: CompanyItemDto[];
};
export type CompanyResponseDto = {
    count: number;
    next?: string;
    previous?: string;
    results: CompanyDto[];
};
export type CompanyTagResponseDto = {
    count: number;
    next?: string;
    previous?: string;
    results: CompanyTagDto[];
};
export type InviteCreateDto = {
    email: string;
};
export type InviteDto = {
    id: string;
    email: string;
    created: string;
};
export type InviteListDto = {
    count: number;
    next?: string;
    previous?: string;
    results: InviteDto[];
};
export type InviteValidationRequestDto = {
    code: string;
};
export type InviteValidationResponseDto = {
    companyId: string;
    existingUser: boolean;
    companyName: string;
    email: string;
};
export type InvitationNewUserDto = {
    code: string;
    name: string;
    email: string;
    password: string;
    passwordConfirmation: string;
};
export type InvitationExistingUserDto = {
    code: string;
};
export type CompanyUpdateDto = {
    name?: string;
};
export type CompanyCreateDto = {
    name: string;
};
export type CompanyCreateResponseDto = {
    id: string;
    name: string;
    created: string;
};
export type ProjectAssetDto = {
    id: string;
    name: string;
    type: string;
    created: string;
    updated: string;
};
export type ProjectSkillDto = {
    id: string;
    name: string;
};
export type ProjectCompanyDto = {
    id: string;
    name: string;
    created: string;
    updated: string;
};
export type KeyValuePair = {
    key: string;
    value: number;
};
export type ProjectDetailsAssetDto = {
    name: string;
    type: string;
};
export type ProjectStatsDto = {
    proposals: number;
    contracts: number;
    unread_messages: number;
};
export type ProjectDto = {
    id: string;
    name: string;
    description: string;
    status: string;
    payment_option: string;
    budget: number;
    logo_url: string;
    created: string;
    updated: string;
    assets: ProjectAssetDto[];
    skills: ProjectSkillDto[];
    company: ProjectCompanyDto;
    stats: ProjectStatsDto;
};
export type ProjectResponseDto = {
    count: number;
    next?: string;
    previous?: string;
    results: ProjectDto[];
};
export type ProjectStatusEnum = "DRAFT" | "ACTIVE" | "COMPLETED" | "CLOSED";
export type ProjectItemDto = {
    id: string;
    name: string;
    companyId: string;
    companyName: string;
    status: ProjectStatusEnum;
    created: string;
};
export type ProjectsFullListDto = {
    totalCount: number;
    totalPages: number;
    page: number;
    nextPage: number;
    results: ProjectItemDto[];
};
export type PatchedProjectRequestDto = {
    name?: string;
};
export type UserSummary = {
    id: string;
    name: string;
    email_sha256: string;
    is_active: boolean;
};
export type ProjectDetailsDto = {
    risks: number;
    contracts: number;
    severities: KeyValuePair[];
    categories: KeyValuePair[];
    statuses: KeyValuePair[];
    environments: KeyValuePair[];
    team: UserSummary[];
    researchers: UserSummary[];
    assets: ProjectDetailsAssetDto;
};
export type PaymentOptionEnum = "HOURLY" | "FIXED";
export type ProjectCreateDto = {
    name: string;
    description: string;
    status: ProjectStatusEnum;
    paymentOption: PaymentOptionEnum;
    budget: number;
    logoUrl: string;
    companyId: string;
};
export type ProjectCreateResponseDto = {
    id: string;
    name: string;
    description: string;
    status: ProjectStatusEnum;
    paymentOption: PaymentOptionEnum;
    budget: number;
    logoUrl: string;
    created: string;
    companyId: string;
};
export type RiskResearcherDto = {
    id: string;
    name: string;
    email_sha256: string;
};
export type SeverityEnum = "INFORMATIONAL" | "LOW" | "MEDIUM" | "HIGH" | "CRITICAL";
export type CategoryEnum = "BROKEN_ACCESS_CONTROL" | "CRYPTOGRAPHIC_FAILURE" | "INJECTION" | "INSECURE_DESIGN" | "SECURITY_MISCONFIGURATION" | "VULNERABLE_AND_OUTDATED_COMPONENTS" | "IDENTIFICATION_AND_AUTH_FAILURE" | "DATA_INTEGRITY_FAILURE" | "SECURE_LOGGING_FAILURE" | "SERVER_SIDE_REQUEST_FORGERY";
export type RiskStatusEnum = "DRAFT" | "REVIEW" | "NEW" | "IN_PROGRESS" | "CLOSED" | "REJECTED" | "DUPLICATE";
export type EnvironmentEnum = "WEB" | "MOBILE" | "API" | "NETWORK";
export type RiskDto = {
    id: string;
    researcher: RiskResearcherDto;
    locked: boolean;
    title: string;
    description: string;
    severity: SeverityEnum;
    category: CategoryEnum;
    status: RiskStatusEnum;
    environment: EnvironmentEnum;
    index: number;
    triage_ready: boolean;
    created: string;
    updated: string;
    closed: string;
    project: string;
    contract: string;
    assignee: string;
    attachments: string[];
    linked_risks: string[];
    comment_count: number;
    pending_unlock: boolean;
    sla: number;
};
export type UnlockRequestDto = {
    id: string;
    risk: string;
    approved: boolean;
    risk_details: RiskDto;
};
export type RiskTagDetailsDto = {
    id: string;
    tag: string;
    color: string;
    created: string;
    updated: string;
    companyId: string;
};
export type RiskTagDto = {
    id: string;
    tag_details: RiskTagDetailsDto;
    created: string;
    updated: string;
    risk: string;
    tag: string;
};
export type HistoryRecordDto = {
    field: string;
    old_value: string;
    new_value: string;
};
export type RiskHistoryDto = {
    id: number;
    user: UserSummary;
    changes: HistoryRecordDto[];
    timestamp: string;
};
export type RiskCommentDto = {
    id: string;
    author: UserSummary;
    content: string;
    deleted: boolean;
    created: string;
    updated: string;
    risk: string;
    attachments: string[];
};
export type RiskResultDto = {
    count: number;
    next?: string;
    previous?: string;
    results: RiskDto[];
};
export type RiskSeverityEnum = "INFORMATIONAL" | "LOW" | "MEDIUM" | "HIGH" | "CRITICAL";
export type RiskItemDto = {
    id: string;
    title: string;
    projectId: string;
    projectName: string;
    researcherId: string;
    researcherName: string;
    created: string;
    severity: RiskSeverityEnum;
    status: RiskStatusEnum;
};
export type RisksFullListDto = {
    totalCount: number;
    totalPages: number;
    page: number;
    nextPage: number;
    results: RiskItemDto[];
};
export type RiskUnlockRequestDto = {
    count: number;
    next?: string;
    previous?: string;
    results: UnlockRequestDto[];
};
export type RiskTagResponseDto = {
    count: number;
    next?: string;
    previous?: string;
    results: RiskTagDto[];
};
export type RiskHistoryResponseDto = {
    count: number;
    next?: string;
    previous?: string;
    results: RiskHistoryDto[];
};
export type LinkedRisksDto = {
    id: string;
    title: string;
};
export type RiskLinkRequestDto = {
    linkedRiskId: string;
};
export type RiskUnlinkRequestDto = {
    linkedRiskId: string;
};
export type RiskCommentListDto = {
    totalCount: number;
    totalPages: number;
    page: number;
    nextPage: number;
    results: RiskCommentDto[];
};
export type CreateUnlockRequestDto = {
    risk: string;
};
export type RiskCommentCreateDto = {
    content: string;
    attachments?: string[];
};
export type RiskCommentResponseDto = {
    count: number;
    next?: string;
    previous?: string;
    results: RiskCommentDto[];
};
export type RiskCommentUpdateDto = {
    content: string;
};
export type RiskUpdateDto = {
    title?: string;
    description?: string;
    severity?: SeverityEnum;
    category?: CategoryEnum;
    status?: RiskStatusEnum;
    environment?: EnvironmentEnum;
    assignee?: string;
    attachmentIds?: string[];
};
export type RiskCreateDto = {
    title: string;
    description: string;
    category: CategoryEnum;
    environment: EnvironmentEnum;
    severity: SeverityEnum;
    status: RiskStatusEnum;
    projectId: string;
    contractId: string;
    attachmentIds?: string[];
};
export type RiskPartialUpdateDto = {
    title?: string;
    description?: string;
    severity?: SeverityEnum;
    category?: CategoryEnum;
    status?: RiskStatusEnum;
    environment?: EnvironmentEnum;
    assignee?: string;
};
export type AvailableStatusesDto = {
    statuses: RiskStatusEnum[];
};
export type ContractResearcherDto = {
    id: string;
    name: string;
    email_sha256: string;
};
export type ContractDto = {
    id: string;
    project: string;
    created: string;
    updated: string;
    payment_option: string;
    rate: number;
    active: boolean;
    researcher: ContractResearcherDto;
};
export type ContractListDto = {
    count: number;
    next: string;
    previous: string;
    results: ContractDto[];
};
export type ContractItemDto = {
    id: string;
    projectId: string;
    projectName: string;
    researcherId: string;
    researcherName: string;
    created: string;
};
export type ContractsFulllistDto = {
    totalCount: number;
    totalPages: number;
    page: number;
    nextPage: number;
    results: ContractItemDto[];
};
export type ProposalResearcherDto = {
    id: string;
    name: string;
    is_active: boolean;
    email_sha256: string;
};
export type ProposalDto = {
    id: string;
    active: boolean;
    created: string;
    updated: string;
    project: string;
    researcher: ProposalResearcherDto;
    payment_option: string;
    rate: number;
    status: string;
};
export type ProposalListDto = {
    count: number;
    next: string;
    previous: string;
    results: ProposalDto[];
};
export type TagCreateRequestDto = {
    tag: string;
    color: string;
    company: string;
};
export type TagDetailDto = {
    id: string;
    tag: string;
    color: string;
    created: string;
    updated: string;
    companyId: string;
};
export type TagUpdateDto = {
    color: string;
};
export type RiskCreateRequestDto = {
    risk: string;
    tag: string;
};
export type RiskTagDetailDto = {
    id: string;
    riskId: string;
    tagId: string;
    created: string;
    updated: string;
};
export type PresignedFieldsDto = {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
};
export type AttachmentDto = {
    id: string;
    key: string;
    filename: string;
    content_type: string;
    size: number;
    url: string;
    deleted: boolean;
    created: string;
    updated: string;
    author: string;
};
export type PresignedUrlCreateDto = {
    filename: string;
    contentType: string;
};
export type PresignedUrlResponseDto = {
    url: string;
    fields: PresignedFieldsDto;
};
export type AttachmentCreateDto = {
    key: string;
    filename: string;
    contentType: string;
    size: number;
};
export type RisksProjectsContractsCompaniesStatsDto = {
    risks: number;
    contracts: number;
    companies: number;
    projects: number;
    risksDiff: number;
    projectsDiff: number;
    contractsDiff: number;
    companiesDiff: number;
};
export type CompanyStatsDto = {
    risks: number;
    contracts: number;
    projects: number;
    users: number;
};
export type AxiosConfig = {
    paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
};
export type Config = {
    mutations?: MutationConfigs;
    axios?: AxiosConfig;
};
export function initialize(axios: AxiosInstance, config?: Config) {
    const requests = makeRequests(axios, config?.axios);
    return {
        requests,
        queries: makeQueries(requests),
        mutations: makeMutations(requests, config?.mutations)
    };
}
function useRapiniMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(mutationFn: MutationFunction<TData, TVariables>, config?: (queryClient: QueryClient) => Pick<UseMutationOptions<TData, TError, TVariables, TContext>, "onSuccess" | "onSettled" | "onError">, options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFn">): UseMutationResult<TData, TError, TVariables, TContext> {
    const { onSuccess, onError, onSettled, ...rest } = options ?? {};
    const queryClient = useQueryClient();
    const conf = config?.(queryClient);
    const mutationOptions: typeof options = {
        onSuccess: (data: TData, variables: TVariables, context?: TContext) => {
            conf?.onSuccess?.(data, variables, context);
            onSuccess?.(data, variables, context);
        },
        onError: (error: TError, variables: TVariables, context?: TContext) => {
            conf?.onError?.(error, variables, context);
            onError?.(error, variables, context);
        },
        onSettled: (data: TData | undefined, error: TError | null, variables: TVariables, context?: TContext) => {
            conf?.onSettled?.(data, error, variables, context);
            onSettled?.(data, error, variables, context);
        },
        ...rest
    };
    return useMutation({ mutationFn, ...mutationOptions });
}
function nullIfUndefined<T>(value: T): NonNullable<T> | null {
    return typeof value === "undefined" ? null : value as NonNullable<T> | null;
}
export const queryKeys = {
    health: () => ["health"] as const,
    userMeRetrieve: () => ["userMeRetrieve"] as const,
    userRetrieve: (userId: string) => ["userRetrieve", userId] as const,
    companyList: () => ["companyList"] as const,
    adminCompanyList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => ["adminCompanyList", nullIfUndefined(page), nullIfUndefined(pageSize), nullIfUndefined(sortBy), nullIfUndefined(sortOrder)] as const,
    companyTagsList: (companyId: string) => ["companyTagsList", companyId] as const,
    companyRetrieve: (companyId: string) => ["companyRetrieve", companyId] as const,
    companyInvitationList: (companyId: string) => ["companyInvitationList", companyId] as const,
    projectList: () => ["projectList"] as const,
    adminProjectList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => ["adminProjectList", nullIfUndefined(page), nullIfUndefined(pageSize), nullIfUndefined(sortBy), nullIfUndefined(sortOrder)] as const,
    projectRetrieve: (projectId: string) => ["projectRetrieve", projectId] as const,
    projectDetailsRetrieve: (projectId: string) => ["projectDetailsRetrieve", projectId] as const,
    riskList: (project: string, assignee?: unknown, category?: unknown, created_after?: string, created_before?: string, page?: number, severity?: unknown, status?: RiskStatusEnum, tag?: unknown) => ["riskList", project, nullIfUndefined(assignee), nullIfUndefined(category), nullIfUndefined(created_after), nullIfUndefined(created_before), nullIfUndefined(page), nullIfUndefined(severity), nullIfUndefined(status), nullIfUndefined(tag)] as const,
    adminRiskList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => ["adminRiskList", nullIfUndefined(page), nullIfUndefined(pageSize), nullIfUndefined(sortBy), nullIfUndefined(sortOrder)] as const,
    unlockRequestList: (riskId?: string, approved?: boolean, page?: number) => ["unlockRequestList", nullIfUndefined(riskId), nullIfUndefined(approved), nullIfUndefined(page)] as const,
    riskTagList: (riskId: string) => ["riskTagList", riskId] as const,
    riskRetrieve: (riskId: string, project?: string, title?: string) => ["riskRetrieve", riskId, nullIfUndefined(project), nullIfUndefined(title)] as const,
    riskHistoryList: (riskId: string) => ["riskHistoryList", riskId] as const,
    riskLinkedRisksList: (riskId: string) => ["riskLinkedRisksList", riskId] as const,
    commentList: (riskId: string, page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => ["commentList", riskId, nullIfUndefined(page), nullIfUndefined(pageSize), nullIfUndefined(sortBy), nullIfUndefined(sortOrder)] as const,
    riskAvailableStatuses: (riskId: string) => ["riskAvailableStatuses", riskId] as const,
    contractList: (project?: string) => ["contractList", nullIfUndefined(project)] as const,
    adminContractList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => ["adminContractList", nullIfUndefined(page), nullIfUndefined(pageSize), nullIfUndefined(sortBy), nullIfUndefined(sortOrder)] as const,
    contractRetrieve: (contractId: string) => ["contractRetrieve", contractId] as const,
    proposalList: (project?: string) => ["proposalList", nullIfUndefined(project)] as const,
    attachmentRetrieve: (attachmentId: string) => ["attachmentRetrieve", attachmentId] as const,
    statsRisksProjectsContractsCompanies: () => ["statsRisksProjectsContractsCompanies"] as const,
    statsCompany: (companyId: string) => ["statsCompany", companyId] as const
} as const;
export type QueryKeys = typeof queryKeys;
function makeRequests(axios: AxiosInstance, config?: AxiosConfig) {
    return {
        health: () => axios.request<unknown>({
            method: "get",
            url: `/health`
        }).then(res => res.data),
        authLoginCreate: (payload: LoginDto) => axios.request<LoginResponseDto>({
            method: "post",
            url: `/auth/login`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        authLogoutCreate: () => axios.request<unknown>({
            method: "post",
            url: `/auth/logout`
        }).then(res => res.data),
        passwordResetCreate: (payload: PasswordResetRequestDto) => axios.request<unknown>({
            method: "post",
            url: `/auth/password_reset`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        passwordResetValidate: (payload: PasswordResetValidateRequestDto) => axios.request<unknown>({
            method: "post",
            url: `/auth/password_reset/validate`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        passwordResetConfirm: (payload: PasswordResetConfirmRequestDto) => axios.request<unknown>({
            method: "post",
            url: `/auth/password_reset/confirm`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        refreshToken: (payload: TokenRefreshRequestDto) => axios.request<TokenRefreshResponseDto>({
            method: "post",
            url: `/auth/refresh_token`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        userMeRetrieve: () => axios.request<UserDto>({
            method: "get",
            url: `/user/me`
        }).then(res => res.data),
        userRetrieve: (userId: string) => axios.request<UserDto>({
            method: "get",
            url: `/user/${userId}`
        }).then(res => res.data),
        userUpdate: (payload: UserUpdateDto) => axios.request<UserDto>({
            method: "put",
            url: `/user`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyList: () => axios.request<CompanyResponseDto>({
            method: "get",
            url: `/company`
        }).then(res => res.data),
        companyCreate: (payload: CompanyCreateDto) => axios.request<CompanyCreateResponseDto>({
            method: "post",
            url: `/company`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        adminCompanyList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => axios.request<CompaniesFullListDto>({
            method: "get",
            url: `/company/list`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(pageSize !== undefined ? { pageSize } : undefined),
                ...(sortBy !== undefined ? { sortBy } : undefined),
                ...(sortOrder !== undefined ? { sortOrder } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        companyTagsList: (companyId: string) => axios.request<CompanyTagResponseDto>({
            method: "get",
            url: `/company/${companyId}/tags`
        }).then(res => res.data),
        companyRetrieve: (companyId: string) => axios.request<CompanyDto>({
            method: "get",
            url: `/company/${companyId}`
        }).then(res => res.data),
        companyUpdate: (payload: CompanyUpdateDto, companyId: string) => axios.request<CompanyDto>({
            method: "put",
            url: `/company/${companyId}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyInvitationList: (companyId: string) => axios.request<InviteListDto>({
            method: "get",
            url: `/company/${companyId}/invite`
        }).then(res => res.data),
        companyInvitationCreate: (payload: InviteCreateDto, companyId: string) => axios.request<InviteDto>({
            method: "post",
            url: `/company/${companyId}/invite`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyInvitationValidate: (payload: InviteValidationRequestDto) => axios.request<InviteValidationResponseDto>({
            method: "post",
            url: `/company/invite/validate`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyInvitationAcceptNewUser: (payload: InvitationNewUserDto) => axios.request<unknown>({
            method: "post",
            url: `/company/invite/accept_new_user`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyInvitationAcceptExistingUser: (payload: InvitationExistingUserDto) => axios.request<unknown>({
            method: "post",
            url: `/company/invite/accept_existing_user`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyInvitationResend: (companyId: string, invitationId: string) => axios.request<unknown>({
            method: "post",
            url: `/company/${companyId}/ivitation/${invitationId}/resend`
        }).then(res => res.data),
        companyInvitationDelete: (companyId: string, invitationId: string) => axios.request<unknown>({
            method: "delete",
            url: `/company/${companyId}/invitation/${invitationId}`
        }).then(res => res.data),
        projectList: () => axios.request<ProjectResponseDto>({
            method: "get",
            url: `/project`
        }).then(res => res.data),
        projectCreate: (payload: ProjectCreateDto) => axios.request<ProjectCreateResponseDto>({
            method: "post",
            url: `/project`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        adminProjectList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => axios.request<ProjectsFullListDto>({
            method: "get",
            url: `/project/list`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(pageSize !== undefined ? { pageSize } : undefined),
                ...(sortBy !== undefined ? { sortBy } : undefined),
                ...(sortOrder !== undefined ? { sortOrder } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        projectRetrieve: (projectId: string) => axios.request<ProjectDto>({
            method: "get",
            url: `/project/${projectId}`
        }).then(res => res.data),
        projectPartialUpdate: (payload: PatchedProjectRequestDto, projectId: string) => axios.request<ProjectDto>({
            method: "patch",
            url: `/project/${projectId}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        projectDetailsRetrieve: (projectId: string) => axios.request<ProjectDetailsDto>({
            method: "get",
            url: `/project/${projectId}/details`
        }).then(res => res.data),
        riskList: (project: string, assignee?: unknown, category?: unknown, created_after?: string, created_before?: string, page?: number, severity?: unknown, status?: RiskStatusEnum, tag?: unknown) => axios.request<RiskResultDto>({
            method: "get",
            url: `/risk`,
            params: {
                project,
                ...(assignee !== undefined ? { assignee } : undefined),
                ...(category !== undefined ? { category } : undefined),
                ...(created_after !== undefined ? { created_after } : undefined),
                ...(created_before !== undefined ? { created_before } : undefined),
                ...(page !== undefined ? { page } : undefined),
                ...(severity !== undefined ? { severity } : undefined),
                ...(status !== undefined ? { status } : undefined),
                ...(tag !== undefined ? { tag } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskCreate: (payload: RiskCreateDto) => axios.request<RiskDto>({
            method: "post",
            url: `/risk`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        adminRiskList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => axios.request<RisksFullListDto>({
            method: "get",
            url: `/risk/list`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(pageSize !== undefined ? { pageSize } : undefined),
                ...(sortBy !== undefined ? { sortBy } : undefined),
                ...(sortOrder !== undefined ? { sortOrder } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        unlockRequestList: (riskId?: string, approved?: boolean, page?: number) => axios.request<RiskUnlockRequestDto>({
            method: "get",
            url: `/risk/unlock_request`,
            params: {
                ...(riskId !== undefined ? { riskId } : undefined),
                ...(approved !== undefined ? { approved } : undefined),
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        unlockRequestCreate: (payload: CreateUnlockRequestDto) => axios.request<UnlockRequestDto>({
            method: "post",
            url: `/risk/unlock_request`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskTagList: (riskId: string) => axios.request<RiskTagResponseDto>({
            method: "get",
            url: `/risk/${riskId}/tags`
        }).then(res => res.data),
        riskRetrieve: (riskId: string, project?: string, title?: string) => axios.request<RiskDto>({
            method: "get",
            url: `/risk/${riskId}`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskUpdate: (payload: RiskUpdateDto, riskId: string) => axios.request<RiskDto>({
            method: "put",
            url: `/risk/${riskId}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskPartialUpdate: (payload: RiskPartialUpdateDto, riskId: string) => axios.request<RiskDto>({
            method: "patch",
            url: `/risk/${riskId}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskHistoryList: (riskId: string) => axios.request<RiskHistoryResponseDto>({
            method: "get",
            url: `/risk/${riskId}/history`
        }).then(res => res.data),
        riskLinkedRisksList: (riskId: string) => axios.request<LinkedRisksDto[]>({
            method: "get",
            url: `/risk/${riskId}/linked_risks`
        }).then(res => res.data),
        riskLinkCreate: (payload: RiskLinkRequestDto, riskId: string) => axios.request<LinkedRisksDto[]>({
            method: "post",
            url: `/risk/${riskId}/linked_risks`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskUnlinkCreate: (payload: RiskUnlinkRequestDto, riskId: string) => axios.request<LinkedRisksDto[]>({
            method: "post",
            url: `/risk/${riskId}/unlink`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        commentList: (riskId: string, page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => axios.request<RiskCommentListDto>({
            method: "get",
            url: `/risk/${riskId}/comments`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(pageSize !== undefined ? { pageSize } : undefined),
                ...(sortBy !== undefined ? { sortBy } : undefined),
                ...(sortOrder !== undefined ? { sortOrder } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskCommentCreate: (payload: RiskCommentCreateDto, riskId: string) => axios.request<RiskCommentResponseDto>({
            method: "post",
            url: `/risk/${riskId}/comment`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskCommentDelete: (riskId: string, commentId: string) => axios.request<unknown>({
            method: "delete",
            url: `/risk/${riskId}/comment/${commentId}`
        }).then(res => res.data),
        riskCommentPartialUpdate: (payload: RiskCommentUpdateDto, riskId: string, commentId: string) => axios.request<RiskCommentResponseDto>({
            method: "patch",
            url: `/risk/${riskId}/comment/${commentId}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskAvailableStatuses: (riskId: string) => axios.request<AvailableStatusesDto>({
            method: "get",
            url: `/risk/${riskId}/available_statuses`
        }).then(res => res.data),
        contractList: (project?: string) => axios.request<ContractListDto>({
            method: "get",
            url: `/contract`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        adminContractList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string) => axios.request<ContractsFulllistDto>({
            method: "get",
            url: `/contract/list`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(pageSize !== undefined ? { pageSize } : undefined),
                ...(sortBy !== undefined ? { sortBy } : undefined),
                ...(sortOrder !== undefined ? { sortOrder } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        contractRetrieve: (contractId: string) => axios.request<ContractDto>({
            method: "get",
            url: `/contract/${contractId}`
        }).then(res => res.data),
        proposalList: (project?: string) => axios.request<ProposalListDto>({
            method: "get",
            url: `/proposal`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        tagCreate: (payload: TagCreateRequestDto) => axios.request<TagDetailDto>({
            method: "post",
            url: `/tag`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        tagDelete: (tagId: string) => axios.request<unknown>({
            method: "delete",
            url: `/tag/${tagId}`
        }).then(res => res.data),
        tagUpdate: (payload: TagUpdateDto, tagId: string) => axios.request<TagDetailDto>({
            method: "put",
            url: `/tag/${tagId}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskTagCreate: (payload: RiskCreateRequestDto) => axios.request<RiskTagDetailDto>({
            method: "post",
            url: `/risk_tag`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskTagDelete: (riskId: string, tagId: string) => axios.request<unknown>({
            method: "delete",
            url: `/risk_tag/${riskId}/tag/${tagId}`
        }).then(res => res.data),
        attachmentRetrieve: (attachmentId: string) => axios.request<AttachmentDto>({
            method: "get",
            url: `/attachment/${attachmentId}`
        }).then(res => res.data),
        attachmentPresignedUrlCreate: (payload: PresignedUrlCreateDto) => axios.request<PresignedUrlResponseDto>({
            method: "post",
            url: `/attachment/presigned_url`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        attachmentCreate: (payload: AttachmentCreateDto) => axios.request<AttachmentDto>({
            method: "post",
            url: `/attachment`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        statsRisksProjectsContractsCompanies: () => axios.request<RisksProjectsContractsCompaniesStatsDto>({
            method: "get",
            url: `/stats/risks_projects_contracts_companies`
        }).then(res => res.data),
        statsCompany: (companyId: string) => axios.request<CompanyStatsDto>({
            method: "get",
            url: `/stats/company/${companyId}`
        }).then(res => res.data)
    } as const;
}
export type Requests = ReturnType<typeof makeRequests>;
export type Response<T extends keyof Requests> = Awaited<ReturnType<Requests[T]>>;
function makeQueries(requests: Requests) {
    return {
        useHealth: (options?: Omit<UseQueryOptions<Response<"health">, unknown, Response<"health">, ReturnType<QueryKeys["health"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"health">, unknown> => useQuery({ queryKey: queryKeys.health(), queryFn: () => requests.health(), ...options }),
        useUserMeRetrieve: (options?: Omit<UseQueryOptions<Response<"userMeRetrieve">, unknown, Response<"userMeRetrieve">, ReturnType<QueryKeys["userMeRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"userMeRetrieve">, unknown> => useQuery({ queryKey: queryKeys.userMeRetrieve(), queryFn: () => requests.userMeRetrieve(), ...options }),
        useUserRetrieve: (userId: string, options?: Omit<UseQueryOptions<Response<"userRetrieve">, unknown, Response<"userRetrieve">, ReturnType<QueryKeys["userRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"userRetrieve">, unknown> => useQuery({ queryKey: queryKeys.userRetrieve(userId), queryFn: () => requests.userRetrieve(userId), ...options }),
        useCompanyList: (options?: Omit<UseQueryOptions<Response<"companyList">, unknown, Response<"companyList">, ReturnType<QueryKeys["companyList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyList">, unknown> => useQuery({ queryKey: queryKeys.companyList(), queryFn: () => requests.companyList(), ...options }),
        useAdminCompanyList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string, options?: Omit<UseQueryOptions<Response<"adminCompanyList">, unknown, Response<"adminCompanyList">, ReturnType<QueryKeys["adminCompanyList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"adminCompanyList">, unknown> => useQuery({ queryKey: queryKeys.adminCompanyList(page, pageSize, sortBy, sortOrder), queryFn: () => requests.adminCompanyList(page, pageSize, sortBy, sortOrder), ...options }),
        useCompanyTagsList: (companyId: string, options?: Omit<UseQueryOptions<Response<"companyTagsList">, unknown, Response<"companyTagsList">, ReturnType<QueryKeys["companyTagsList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyTagsList">, unknown> => useQuery({ queryKey: queryKeys.companyTagsList(companyId), queryFn: () => requests.companyTagsList(companyId), ...options }),
        useCompanyRetrieve: (companyId: string, options?: Omit<UseQueryOptions<Response<"companyRetrieve">, unknown, Response<"companyRetrieve">, ReturnType<QueryKeys["companyRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyRetrieve">, unknown> => useQuery({ queryKey: queryKeys.companyRetrieve(companyId), queryFn: () => requests.companyRetrieve(companyId), ...options }),
        useCompanyInvitationList: (companyId: string, options?: Omit<UseQueryOptions<Response<"companyInvitationList">, unknown, Response<"companyInvitationList">, ReturnType<QueryKeys["companyInvitationList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyInvitationList">, unknown> => useQuery({ queryKey: queryKeys.companyInvitationList(companyId), queryFn: () => requests.companyInvitationList(companyId), ...options }),
        useProjectList: (options?: Omit<UseQueryOptions<Response<"projectList">, unknown, Response<"projectList">, ReturnType<QueryKeys["projectList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"projectList">, unknown> => useQuery({ queryKey: queryKeys.projectList(), queryFn: () => requests.projectList(), ...options }),
        useAdminProjectList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string, options?: Omit<UseQueryOptions<Response<"adminProjectList">, unknown, Response<"adminProjectList">, ReturnType<QueryKeys["adminProjectList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"adminProjectList">, unknown> => useQuery({ queryKey: queryKeys.adminProjectList(page, pageSize, sortBy, sortOrder), queryFn: () => requests.adminProjectList(page, pageSize, sortBy, sortOrder), ...options }),
        useProjectRetrieve: (projectId: string, options?: Omit<UseQueryOptions<Response<"projectRetrieve">, unknown, Response<"projectRetrieve">, ReturnType<QueryKeys["projectRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"projectRetrieve">, unknown> => useQuery({ queryKey: queryKeys.projectRetrieve(projectId), queryFn: () => requests.projectRetrieve(projectId), ...options }),
        useProjectDetailsRetrieve: (projectId: string, options?: Omit<UseQueryOptions<Response<"projectDetailsRetrieve">, unknown, Response<"projectDetailsRetrieve">, ReturnType<QueryKeys["projectDetailsRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"projectDetailsRetrieve">, unknown> => useQuery({ queryKey: queryKeys.projectDetailsRetrieve(projectId), queryFn: () => requests.projectDetailsRetrieve(projectId), ...options }),
        useRiskList: (project: string, assignee?: unknown, category?: unknown, created_after?: string, created_before?: string, page?: number, severity?: unknown, status?: RiskStatusEnum, tag?: unknown, options?: Omit<UseQueryOptions<Response<"riskList">, unknown, Response<"riskList">, ReturnType<QueryKeys["riskList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskList">, unknown> => useQuery({ queryKey: queryKeys.riskList(project, assignee, category, created_after, created_before, page, severity, status, tag), queryFn: () => requests.riskList(project, assignee, category, created_after, created_before, page, severity, status, tag), ...options }),
        useAdminRiskList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string, options?: Omit<UseQueryOptions<Response<"adminRiskList">, unknown, Response<"adminRiskList">, ReturnType<QueryKeys["adminRiskList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"adminRiskList">, unknown> => useQuery({ queryKey: queryKeys.adminRiskList(page, pageSize, sortBy, sortOrder), queryFn: () => requests.adminRiskList(page, pageSize, sortBy, sortOrder), ...options }),
        useUnlockRequestList: (riskId?: string, approved?: boolean, page?: number, options?: Omit<UseQueryOptions<Response<"unlockRequestList">, unknown, Response<"unlockRequestList">, ReturnType<QueryKeys["unlockRequestList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"unlockRequestList">, unknown> => useQuery({ queryKey: queryKeys.unlockRequestList(riskId, approved, page), queryFn: () => requests.unlockRequestList(riskId, approved, page), ...options }),
        useRiskTagList: (riskId: string, options?: Omit<UseQueryOptions<Response<"riskTagList">, unknown, Response<"riskTagList">, ReturnType<QueryKeys["riskTagList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskTagList">, unknown> => useQuery({ queryKey: queryKeys.riskTagList(riskId), queryFn: () => requests.riskTagList(riskId), ...options }),
        useRiskRetrieve: (riskId: string, project?: string, title?: string, options?: Omit<UseQueryOptions<Response<"riskRetrieve">, unknown, Response<"riskRetrieve">, ReturnType<QueryKeys["riskRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskRetrieve">, unknown> => useQuery({ queryKey: queryKeys.riskRetrieve(riskId, project, title), queryFn: () => requests.riskRetrieve(riskId, project, title), ...options }),
        useRiskHistoryList: (riskId: string, options?: Omit<UseQueryOptions<Response<"riskHistoryList">, unknown, Response<"riskHistoryList">, ReturnType<QueryKeys["riskHistoryList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskHistoryList">, unknown> => useQuery({ queryKey: queryKeys.riskHistoryList(riskId), queryFn: () => requests.riskHistoryList(riskId), ...options }),
        useRiskLinkedRisksList: (riskId: string, options?: Omit<UseQueryOptions<Response<"riskLinkedRisksList">, unknown, Response<"riskLinkedRisksList">, ReturnType<QueryKeys["riskLinkedRisksList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskLinkedRisksList">, unknown> => useQuery({ queryKey: queryKeys.riskLinkedRisksList(riskId), queryFn: () => requests.riskLinkedRisksList(riskId), ...options }),
        useCommentList: (riskId: string, page?: number, pageSize?: number, sortBy?: string, sortOrder?: string, options?: Omit<UseQueryOptions<Response<"commentList">, unknown, Response<"commentList">, ReturnType<QueryKeys["commentList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"commentList">, unknown> => useQuery({ queryKey: queryKeys.commentList(riskId, page, pageSize, sortBy, sortOrder), queryFn: () => requests.commentList(riskId, page, pageSize, sortBy, sortOrder), ...options }),
        useRiskAvailableStatuses: (riskId: string, options?: Omit<UseQueryOptions<Response<"riskAvailableStatuses">, unknown, Response<"riskAvailableStatuses">, ReturnType<QueryKeys["riskAvailableStatuses"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskAvailableStatuses">, unknown> => useQuery({ queryKey: queryKeys.riskAvailableStatuses(riskId), queryFn: () => requests.riskAvailableStatuses(riskId), ...options }),
        useContractList: (project?: string, options?: Omit<UseQueryOptions<Response<"contractList">, unknown, Response<"contractList">, ReturnType<QueryKeys["contractList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"contractList">, unknown> => useQuery({ queryKey: queryKeys.contractList(project), queryFn: () => requests.contractList(project), ...options }),
        useAdminContractList: (page?: number, pageSize?: number, sortBy?: string, sortOrder?: string, options?: Omit<UseQueryOptions<Response<"adminContractList">, unknown, Response<"adminContractList">, ReturnType<QueryKeys["adminContractList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"adminContractList">, unknown> => useQuery({ queryKey: queryKeys.adminContractList(page, pageSize, sortBy, sortOrder), queryFn: () => requests.adminContractList(page, pageSize, sortBy, sortOrder), ...options }),
        useContractRetrieve: (contractId: string, options?: Omit<UseQueryOptions<Response<"contractRetrieve">, unknown, Response<"contractRetrieve">, ReturnType<QueryKeys["contractRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"contractRetrieve">, unknown> => useQuery({ queryKey: queryKeys.contractRetrieve(contractId), queryFn: () => requests.contractRetrieve(contractId), ...options }),
        useProposalList: (project?: string, options?: Omit<UseQueryOptions<Response<"proposalList">, unknown, Response<"proposalList">, ReturnType<QueryKeys["proposalList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"proposalList">, unknown> => useQuery({ queryKey: queryKeys.proposalList(project), queryFn: () => requests.proposalList(project), ...options }),
        useAttachmentRetrieve: (attachmentId: string, options?: Omit<UseQueryOptions<Response<"attachmentRetrieve">, unknown, Response<"attachmentRetrieve">, ReturnType<QueryKeys["attachmentRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"attachmentRetrieve">, unknown> => useQuery({ queryKey: queryKeys.attachmentRetrieve(attachmentId), queryFn: () => requests.attachmentRetrieve(attachmentId), ...options }),
        useStatsRisksProjectsContractsCompanies: (options?: Omit<UseQueryOptions<Response<"statsRisksProjectsContractsCompanies">, unknown, Response<"statsRisksProjectsContractsCompanies">, ReturnType<QueryKeys["statsRisksProjectsContractsCompanies"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"statsRisksProjectsContractsCompanies">, unknown> => useQuery({ queryKey: queryKeys.statsRisksProjectsContractsCompanies(), queryFn: () => requests.statsRisksProjectsContractsCompanies(), ...options }),
        useStatsCompany: (companyId: string, options?: Omit<UseQueryOptions<Response<"statsCompany">, unknown, Response<"statsCompany">, ReturnType<QueryKeys["statsCompany"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"statsCompany">, unknown> => useQuery({ queryKey: queryKeys.statsCompany(companyId), queryFn: () => requests.statsCompany(companyId), ...options })
    } as const;
}
type MutationConfigs = {
    useAuthLoginCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"authLoginCreate">, unknown, Parameters<Requests["authLoginCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useAuthLogoutCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"authLogoutCreate">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    usePasswordResetCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"passwordResetCreate">, unknown, Parameters<Requests["passwordResetCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    usePasswordResetValidate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"passwordResetValidate">, unknown, Parameters<Requests["passwordResetValidate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    usePasswordResetConfirm?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"passwordResetConfirm">, unknown, Parameters<Requests["passwordResetConfirm"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRefreshToken?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"refreshToken">, unknown, Parameters<Requests["refreshToken"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUserUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"userUpdate">, unknown, Parameters<Requests["userUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyCreate">, unknown, Parameters<Requests["companyCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyUpdate">, unknown, Parameters<Requests["companyUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyInvitationCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyInvitationCreate">, unknown, Parameters<Requests["companyInvitationCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyInvitationValidate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyInvitationValidate">, unknown, Parameters<Requests["companyInvitationValidate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyInvitationAcceptNewUser?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyInvitationAcceptNewUser">, unknown, Parameters<Requests["companyInvitationAcceptNewUser"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyInvitationAcceptExistingUser?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyInvitationAcceptExistingUser">, unknown, Parameters<Requests["companyInvitationAcceptExistingUser"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyInvitationResend?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyInvitationResend">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyInvitationDelete?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyInvitationDelete">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useProjectCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"projectCreate">, unknown, Parameters<Requests["projectCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProjectPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"projectPartialUpdate">, unknown, Parameters<Requests["projectPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskCreate">, unknown, Parameters<Requests["riskCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUnlockRequestCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"unlockRequestCreate">, unknown, Parameters<Requests["unlockRequestCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskUpdate">, unknown, Parameters<Requests["riskUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskPartialUpdate">, unknown, Parameters<Requests["riskPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskLinkCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskLinkCreate">, unknown, Parameters<Requests["riskLinkCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskUnlinkCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskUnlinkCreate">, unknown, Parameters<Requests["riskUnlinkCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskCommentCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskCommentCreate">, unknown, Parameters<Requests["riskCommentCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskCommentPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskCommentPartialUpdate">, unknown, Parameters<Requests["riskCommentPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskCommentDelete?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskCommentDelete">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useTagCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"tagCreate">, unknown, Parameters<Requests["tagCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useTagUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"tagUpdate">, unknown, Parameters<Requests["tagUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useTagDelete?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"tagDelete">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskTagCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskTagCreate">, unknown, Parameters<Requests["riskTagCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskTagDelete?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskTagDelete">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useAttachmentPresignedUrlCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"attachmentPresignedUrlCreate">, unknown, Parameters<Requests["attachmentPresignedUrlCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useAttachmentCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"attachmentCreate">, unknown, Parameters<Requests["attachmentCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
};
function makeMutations(requests: Requests, config?: Config["mutations"]) {
    return {
        useAuthLoginCreate: (options?: Omit<UseMutationOptions<Response<"authLoginCreate">, unknown, Parameters<Requests["authLoginCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"authLoginCreate">, unknown, Parameters<Requests["authLoginCreate"]>[0]>(payload => requests.authLoginCreate(payload), config?.useAuthLoginCreate, options),
        useAuthLogoutCreate: (options?: Omit<UseMutationOptions<Response<"authLogoutCreate">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"authLogoutCreate">, unknown, unknown>(() => requests.authLogoutCreate(), config?.useAuthLogoutCreate, options),
        usePasswordResetCreate: (options?: Omit<UseMutationOptions<Response<"passwordResetCreate">, unknown, Parameters<Requests["passwordResetCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"passwordResetCreate">, unknown, Parameters<Requests["passwordResetCreate"]>[0]>(payload => requests.passwordResetCreate(payload), config?.usePasswordResetCreate, options),
        usePasswordResetValidate: (options?: Omit<UseMutationOptions<Response<"passwordResetValidate">, unknown, Parameters<Requests["passwordResetValidate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"passwordResetValidate">, unknown, Parameters<Requests["passwordResetValidate"]>[0]>(payload => requests.passwordResetValidate(payload), config?.usePasswordResetValidate, options),
        usePasswordResetConfirm: (options?: Omit<UseMutationOptions<Response<"passwordResetConfirm">, unknown, Parameters<Requests["passwordResetConfirm"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"passwordResetConfirm">, unknown, Parameters<Requests["passwordResetConfirm"]>[0]>(payload => requests.passwordResetConfirm(payload), config?.usePasswordResetConfirm, options),
        useRefreshToken: (options?: Omit<UseMutationOptions<Response<"refreshToken">, unknown, Parameters<Requests["refreshToken"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"refreshToken">, unknown, Parameters<Requests["refreshToken"]>[0]>(payload => requests.refreshToken(payload), config?.useRefreshToken, options),
        useUserUpdate: (options?: Omit<UseMutationOptions<Response<"userUpdate">, unknown, Parameters<Requests["userUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"userUpdate">, unknown, Parameters<Requests["userUpdate"]>[0]>(payload => requests.userUpdate(payload), config?.useUserUpdate, options),
        useCompanyCreate: (options?: Omit<UseMutationOptions<Response<"companyCreate">, unknown, Parameters<Requests["companyCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyCreate">, unknown, Parameters<Requests["companyCreate"]>[0]>(payload => requests.companyCreate(payload), config?.useCompanyCreate, options),
        useCompanyUpdate: (companyId: string, options?: Omit<UseMutationOptions<Response<"companyUpdate">, unknown, Parameters<Requests["companyUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyUpdate">, unknown, Parameters<Requests["companyUpdate"]>[0]>(payload => requests.companyUpdate(payload, companyId), config?.useCompanyUpdate, options),
        useCompanyInvitationCreate: (companyId: string, options?: Omit<UseMutationOptions<Response<"companyInvitationCreate">, unknown, Parameters<Requests["companyInvitationCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyInvitationCreate">, unknown, Parameters<Requests["companyInvitationCreate"]>[0]>(payload => requests.companyInvitationCreate(payload, companyId), config?.useCompanyInvitationCreate, options),
        useCompanyInvitationValidate: (options?: Omit<UseMutationOptions<Response<"companyInvitationValidate">, unknown, Parameters<Requests["companyInvitationValidate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyInvitationValidate">, unknown, Parameters<Requests["companyInvitationValidate"]>[0]>(payload => requests.companyInvitationValidate(payload), config?.useCompanyInvitationValidate, options),
        useCompanyInvitationAcceptNewUser: (options?: Omit<UseMutationOptions<Response<"companyInvitationAcceptNewUser">, unknown, Parameters<Requests["companyInvitationAcceptNewUser"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyInvitationAcceptNewUser">, unknown, Parameters<Requests["companyInvitationAcceptNewUser"]>[0]>(payload => requests.companyInvitationAcceptNewUser(payload), config?.useCompanyInvitationAcceptNewUser, options),
        useCompanyInvitationAcceptExistingUser: (options?: Omit<UseMutationOptions<Response<"companyInvitationAcceptExistingUser">, unknown, Parameters<Requests["companyInvitationAcceptExistingUser"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyInvitationAcceptExistingUser">, unknown, Parameters<Requests["companyInvitationAcceptExistingUser"]>[0]>(payload => requests.companyInvitationAcceptExistingUser(payload), config?.useCompanyInvitationAcceptExistingUser, options),
        useCompanyInvitationResend: (companyId: string, invitationId: string, options?: Omit<UseMutationOptions<Response<"companyInvitationResend">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"companyInvitationResend">, unknown, unknown>(() => requests.companyInvitationResend(companyId, invitationId), config?.useCompanyInvitationResend, options),
        useCompanyInvitationDelete: (companyId: string, invitationId: string, options?: Omit<UseMutationOptions<Response<"companyInvitationDelete">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"companyInvitationDelete">, unknown, unknown>(() => requests.companyInvitationDelete(companyId, invitationId), config?.useCompanyInvitationDelete, options),
        useProjectCreate: (options?: Omit<UseMutationOptions<Response<"projectCreate">, unknown, Parameters<Requests["projectCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"projectCreate">, unknown, Parameters<Requests["projectCreate"]>[0]>(payload => requests.projectCreate(payload), config?.useProjectCreate, options),
        useProjectPartialUpdate: (projectId: string, options?: Omit<UseMutationOptions<Response<"projectPartialUpdate">, unknown, Parameters<Requests["projectPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"projectPartialUpdate">, unknown, Parameters<Requests["projectPartialUpdate"]>[0]>(payload => requests.projectPartialUpdate(payload, projectId), config?.useProjectPartialUpdate, options),
        useRiskCreate: (options?: Omit<UseMutationOptions<Response<"riskCreate">, unknown, Parameters<Requests["riskCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskCreate">, unknown, Parameters<Requests["riskCreate"]>[0]>(payload => requests.riskCreate(payload), config?.useRiskCreate, options),
        useUnlockRequestCreate: (options?: Omit<UseMutationOptions<Response<"unlockRequestCreate">, unknown, Parameters<Requests["unlockRequestCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"unlockRequestCreate">, unknown, Parameters<Requests["unlockRequestCreate"]>[0]>(payload => requests.unlockRequestCreate(payload), config?.useUnlockRequestCreate, options),
        useRiskUpdate: (riskId: string, options?: Omit<UseMutationOptions<Response<"riskUpdate">, unknown, Parameters<Requests["riskUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskUpdate">, unknown, Parameters<Requests["riskUpdate"]>[0]>(payload => requests.riskUpdate(payload, riskId), config?.useRiskUpdate, options),
        useRiskPartialUpdate: (riskId: string, options?: Omit<UseMutationOptions<Response<"riskPartialUpdate">, unknown, Parameters<Requests["riskPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskPartialUpdate">, unknown, Parameters<Requests["riskPartialUpdate"]>[0]>(payload => requests.riskPartialUpdate(payload, riskId), config?.useRiskPartialUpdate, options),
        useRiskLinkCreate: (riskId: string, options?: Omit<UseMutationOptions<Response<"riskLinkCreate">, unknown, Parameters<Requests["riskLinkCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskLinkCreate">, unknown, Parameters<Requests["riskLinkCreate"]>[0]>(payload => requests.riskLinkCreate(payload, riskId), config?.useRiskLinkCreate, options),
        useRiskUnlinkCreate: (riskId: string, options?: Omit<UseMutationOptions<Response<"riskUnlinkCreate">, unknown, Parameters<Requests["riskUnlinkCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskUnlinkCreate">, unknown, Parameters<Requests["riskUnlinkCreate"]>[0]>(payload => requests.riskUnlinkCreate(payload, riskId), config?.useRiskUnlinkCreate, options),
        useRiskCommentCreate: (riskId: string, options?: Omit<UseMutationOptions<Response<"riskCommentCreate">, unknown, Parameters<Requests["riskCommentCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskCommentCreate">, unknown, Parameters<Requests["riskCommentCreate"]>[0]>(payload => requests.riskCommentCreate(payload, riskId), config?.useRiskCommentCreate, options),
        useRiskCommentPartialUpdate: (riskId: string, commentId: string, options?: Omit<UseMutationOptions<Response<"riskCommentPartialUpdate">, unknown, Parameters<Requests["riskCommentPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskCommentPartialUpdate">, unknown, Parameters<Requests["riskCommentPartialUpdate"]>[0]>(payload => requests.riskCommentPartialUpdate(payload, riskId, commentId), config?.useRiskCommentPartialUpdate, options),
        useRiskCommentDelete: (riskId: string, commentId: string, options?: Omit<UseMutationOptions<Response<"riskCommentDelete">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"riskCommentDelete">, unknown, unknown>(() => requests.riskCommentDelete(riskId, commentId), config?.useRiskCommentDelete, options),
        useTagCreate: (options?: Omit<UseMutationOptions<Response<"tagCreate">, unknown, Parameters<Requests["tagCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"tagCreate">, unknown, Parameters<Requests["tagCreate"]>[0]>(payload => requests.tagCreate(payload), config?.useTagCreate, options),
        useTagUpdate: (tagId: string, options?: Omit<UseMutationOptions<Response<"tagUpdate">, unknown, Parameters<Requests["tagUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"tagUpdate">, unknown, Parameters<Requests["tagUpdate"]>[0]>(payload => requests.tagUpdate(payload, tagId), config?.useTagUpdate, options),
        useTagDelete: (tagId: string, options?: Omit<UseMutationOptions<Response<"tagDelete">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"tagDelete">, unknown, unknown>(() => requests.tagDelete(tagId), config?.useTagDelete, options),
        useRiskTagCreate: (options?: Omit<UseMutationOptions<Response<"riskTagCreate">, unknown, Parameters<Requests["riskTagCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskTagCreate">, unknown, Parameters<Requests["riskTagCreate"]>[0]>(payload => requests.riskTagCreate(payload), config?.useRiskTagCreate, options),
        useRiskTagDelete: (riskId: string, tagId: string, options?: Omit<UseMutationOptions<Response<"riskTagDelete">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"riskTagDelete">, unknown, unknown>(() => requests.riskTagDelete(riskId, tagId), config?.useRiskTagDelete, options),
        useAttachmentPresignedUrlCreate: (options?: Omit<UseMutationOptions<Response<"attachmentPresignedUrlCreate">, unknown, Parameters<Requests["attachmentPresignedUrlCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"attachmentPresignedUrlCreate">, unknown, Parameters<Requests["attachmentPresignedUrlCreate"]>[0]>(payload => requests.attachmentPresignedUrlCreate(payload), config?.useAttachmentPresignedUrlCreate, options),
        useAttachmentCreate: (options?: Omit<UseMutationOptions<Response<"attachmentCreate">, unknown, Parameters<Requests["attachmentCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"attachmentCreate">, unknown, Parameters<Requests["attachmentCreate"]>[0]>(payload => requests.attachmentCreate(payload), config?.useAttachmentCreate, options)
    } as const;
}
