import { createContext, ReactNode } from 'react';
import { CompanyDto } from '@/api-client';

import { apiClient } from '@/globals';
import { useUserType } from '@/hooks/use-user-type';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';

interface CompanyContextType {
  company: CompanyDto;
}

interface CompanyContextProviderProps {
  companyId: string;
  children: ReactNode;
}

export const CompanyContext = createContext<CompanyContextType | null>(null);

export const CompanyContextProvider = ({ companyId, children }: CompanyContextProviderProps) => {
  const userType = useUserType();

  const {
    data: company,
    isLoading,
    isError,
    error,
  } = apiClient.queries.useCompanyRetrieve(companyId, {
    enabled: userType !== 'RESEARCHER',
    retry: false,
  });

  if (userType === 'RESEARCHER') {
    return children;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !company) {
    throw error;
  }

  return <CompanyContext.Provider value={{ company }}>{children}</CompanyContext.Provider>;
};
