import { Button, Divider, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { z } from 'zod';
import { useCompany } from '@/hooks/use-company';
import { apiClient, queryClient } from '@/globals';
import { CompanyUpdateDto, queryKeys } from '@/api-client';
import classes from './Settings.module.css';

const companySettingsSchema = z.object({
  companyName: z.string().min(1, 'Company name is required'),
});

type CompanySettingsValues = z.infer<typeof companySettingsSchema>;

export const CompanySettingsForm = () => {
  const company = useCompany();

  const mutation = useMutation({
    mutationFn: ({ companyId, payload }: { companyId: string; payload: CompanyUpdateDto }) =>
      apiClient.requests.companyUpdate(payload, companyId),
    onSuccess: async () => {
      notifications.show({
        title: 'Settings Updated',
        message: 'Successfully updated company settings',
        color: 'green',
      });

      await queryClient.invalidateQueries({
        queryKey: queryKeys.companyList(),
      });
    },
    onError: () => {
      notifications.show({
        title: 'Settings Update Failed',
        message: 'Failed to update company settings',
        color: 'red',
      });
    },
    retry: false,
  });

  const form = useForm<CompanySettingsValues>({
    validateInputOnChange: true,
    initialValues: {
      companyName: company.name,
    },
    validate: zodResolver(companySettingsSchema),
  });

  const { reset, onSubmit, isValid, isDirty } = form;

  useEffect(() => {
    reset();
  }, [company, reset]);

  const handleSubmit = (values: CompanySettingsValues) => {
    mutation.mutate({
      companyId: company.id,
      payload: {
        name: values.companyName,
      },
    });
  };

  return (
    <Stack>
      <SimpleGrid key={`${company.id}-${company.name}`} cols={{ base: 1, md: 2 }}>
        <form onSubmit={onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label="Company name"
              placeholder={company.name} // Set the company name as a placeholder
              classNames={classes}
              {...form.getInputProps('companyName')}
            />
            <Group>
              <Button type="submit" disabled={!isDirty() || !isValid() || mutation.isPending}>
                Save
              </Button>
              <Button variant="outline" color="gray" onClick={reset}>
                Cancel
              </Button>
            </Group>
          </Stack>
        </form>
      </SimpleGrid>
      <Divider />
    </Stack>
  );
};
