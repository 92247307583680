import { RiskDto } from '@/api-client';
import { CardProps, ColumnItems } from './props';

const riskToCardProps = (risk: RiskDto): CardProps => {
  const attachmentCount = risk.attachments.length;
  return {
    index: risk.index,
    id: risk.id,
    title: risk.title,
    content: risk.description,
    date: risk.created,
    severity: risk.severity,
    comments: risk.comment_count,
    attachments: attachmentCount,
    risk,
  } as CardProps;
};

/**
 * Represents a risk board.
 */
export class RiskBoard {
  private readonly columnItems: ColumnItems;

  constructor(risks?: RiskDto[]) {
    this.columnItems = {
      DRAFT: {
        id: 'DRAFT',
        list: [],
      },
      REVIEW: {
        id: 'REVIEW',
        list: [],
      },
      NEW: {
        id: 'NEW',
        list: [],
      },
      IN_PROGRESS: {
        id: 'IN_PROGRESS',
        list: [],
      },
      CLOSED: {
        id: 'CLOSED',
        list: [],
      },
      REJECTED: {
        id: 'REJECTED',
        list: [],
      },
      DUPLICATE: {
        id: 'DUPLICATE',
        list: [],
      },
    };

    if (risks) {
      this.addRisks(risks);
    }
  }

  /**
   * Add a risk to the corresponding status in the columnItems list.
   *
   * @param {Risk} risk - The risk to be added.
   */
  addRisk(risk: RiskDto) {
    const props = riskToCardProps(risk);
    this.columnItems[risk.status].list.push(props);
  }

  /**
   * Adds multiple risks to the system.
   *
   * @param {Risk[]} risks - An array of risks to be added.
   */
  addRisks(risks: RiskDto[]) {
    risks.forEach((risk) => {
      this.addRisk(risk);
    });
  }

  get items() {
    Object.keys(this.columnItems).forEach((key) => {
      this.columnItems[key].list = this.columnItems[key].list.sort((a, b) => a.index - b.index);
    });
    return this.columnItems;
  }
}
