import { LoaderFunctionArgs } from '@remix-run/router/utils';
import { json, LoaderFunction } from 'react-router-dom';
import { queryKeys } from '@/api-client';
import { apiClient, queryClient } from '@/globals';

const proposalQuery = (id: string) => ({
  queryKey: queryKeys.proposalRetrieve(id),
  queryFn: async () => apiClient.requests.proposalRetrieve(id),
});

const conversationListQuery = (proposalId: string) => ({
  queryKey: queryKeys.conversationList(proposalId, 1),
  queryFn: async () => apiClient.requests.conversationList(undefined, 1),
});

const conversationQuery = (conversationId: string) => ({
  queryKey: queryKeys.conversationRetrieve(conversationId),
  queryFn: async () => apiClient.requests.conversationRetrieve(conversationId),
});

const userMeQuery = () => ({
  queryKey: queryKeys.userMeRetrieve(),
  queryFn: async () => apiClient.requests.userMeRetrieve(),
});

const messageListQuery = (conversationId: string) => ({
  queryKey: queryKeys.messageList(conversationId, 0),
  queryFn: async () => apiClient.requests.messageList(conversationId, 1),
});

export const ProposalDetailLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const { proposalId } = params;
  if (proposalId === undefined) {
    throw json({ message: 'proposalId is required' }, { status: 401 });
  }

  const proposal = await queryClient.fetchQuery({
    ...proposalQuery(proposalId),
    staleTime: 1000 * 60,
  });

  const conversationList = await queryClient.fetchQuery({
    ...conversationListQuery(proposalId),
    staleTime: 1000 * 60,
  });

  const conversationId = conversationList.results[0].id;
  if (conversationId === undefined) {
    throw json({ message: 'failed to find conversationId' }, { status: 401 });
  }

  const conversation = await queryClient.fetchQuery({
    ...conversationQuery(conversationId),
    staleTime: 1000 * 60,
  });

  const userMe = await queryClient.fetchQuery({
    ...userMeQuery(),
    staleTime: 1000 * 60,
  });

  await queryClient.fetchQuery({
    ...messageListQuery(conversationId),
    staleTime: 1000 * 60,
  });

  return { proposal, conversation, userMe };
};
