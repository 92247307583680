import moment from 'moment';

import { IconLock, IconLockOpen } from '@tabler/icons-react';
import { Group, Stack, Text, Title, rem } from '@mantine/core';

import Gravatar from '@/components/Common/Gravatar/Gravatar';
import SeverityStatus from '@/components/Common/SeverityStatus/SeverityStatus';
import { RiskCategoryBadge } from '@/components/Risks/RiskCategoryBadge/RiskCategoryBadge';
import { RiskStatusBadge } from '@/components/Risks/RiskStatusBadge/RiskStatusBadge';
import { RiskEnvironmentsBadge } from '@/components/Risks/RiskEnvironmentBadge/RiskEnvironmentBadge';

import classes from './RiskHistoryCard.module.css';

import type {
  CategoryEnum,
  EnvironmentEnum,
  RiskHistoryDto,
  RiskStatusEnum,
  SeverityEnum,
} from '@/api-client';

type HistoryChange = RiskHistoryDto['changes'][number];

const DefaultHistoryBody: React.FC<HistoryChange> = ({ field, old_value, new_value }) => (
  <Text>
    Changed <b>{field}</b> from <b>{old_value}</b> to <b>{new_value}</b>
  </Text>
);

const UpdatedRiskLockBody: React.FC<HistoryChange> = ({ new_value }) =>
  new_value === 'True' ? (
    <Group gap="sm">
      <Text>Locked the risk</Text>
      <IconLock stroke={1.5} size={16} />
    </Group>
  ) : (
    <Group gap="sm">
      <Text>Unlocked the risk</Text>
      <IconLockOpen stroke={1.5} size={16} />
    </Group>
  );

const UpdatedSeverityBody: React.FC<HistoryChange> = ({ old_value, new_value }) => (
  <Group>
    <Text>
      Changed <b>severity</b> from{' '}
    </Text>
    <SeverityStatus severity={old_value as SeverityEnum} />
    <Text>to</Text>
    <SeverityStatus severity={new_value as SeverityEnum} />
  </Group>
);

const UpdatedCategoryBody: React.FC<HistoryChange> = ({ old_value, new_value }) => (
  <Group>
    <Text>
      Changed <b>category</b> from{' '}
    </Text>
    <RiskCategoryBadge size="sm" category={old_value.toUpperCase() as CategoryEnum} />
    <Text>to</Text>
    <RiskCategoryBadge size="sm" category={new_value.toUpperCase() as CategoryEnum} />
  </Group>
);

const UpdatedEnvironmentBody: React.FC<HistoryChange> = ({ old_value, new_value }) => (
  <Group>
    <Text>
      Changed <b>environment</b> from{' '}
    </Text>
    <RiskEnvironmentsBadge environment={old_value as EnvironmentEnum} />
    <Text>to</Text>
    <RiskEnvironmentsBadge environment={new_value as EnvironmentEnum} />
  </Group>
);

const UpdatedStatusBody: React.FC<HistoryChange> = ({ old_value, new_value }) => (
  <Group>
    <Text>
      Changed <b>status</b> from{' '}
    </Text>
    <RiskStatusBadge size="sm" status={old_value.toUpperCase() as RiskStatusEnum} />
    <Text>to</Text>
    <RiskStatusBadge size="sm" status={new_value.toUpperCase() as RiskStatusEnum} />
  </Group>
);

enum HistoryType {
  UpdatedStatus = 'status',
  UpdatedCategory = 'category',
  UpdatedSeverity = 'severity',
  UpdatedRiskLock = 'locked',
  UpdatedEnvironment = 'environment',
}

const historyBodyMap = {
  [HistoryType.UpdatedStatus]: UpdatedStatusBody,
  [HistoryType.UpdatedCategory]: UpdatedCategoryBody,
  [HistoryType.UpdatedSeverity]: UpdatedSeverityBody,
  [HistoryType.UpdatedRiskLock]: UpdatedRiskLockBody,
  [HistoryType.UpdatedEnvironment]: UpdatedEnvironmentBody,
};

export const RiskHistoryCard = ({ riskHistory }: { riskHistory: RiskHistoryDto }) => {
  const { changes, user, timestamp } = riskHistory;

  return (
    <Group className={classes.historyCard} align="flex-start" wrap="nowrap" gap="md" pt="md">
      {/* eslint-disable-next-line */}
      <Gravatar emailHash={user?.email_sha256} size={32} withoutTooltip />

      <Stack px="md" className={classes.container} gap={rem(6)} w="100%">
        <Group>
          <Title order={4} textfor="avatar">
            {/* eslint-disable-next-line */}
            {user?.name}
          </Title>

          <Text textfor="info">{moment(timestamp).format('lll')}</Text>
        </Group>

        <Stack gap="sm">
          {changes.map((change, index) => {
            const Body: React.FC<HistoryChange> =
              // eslint-disable-next-line
              historyBodyMap?.[change.field as HistoryType] ?? DefaultHistoryBody;

            return (
              <Body
                key={index}
                field={change.field}
                old_value={change.old_value}
                new_value={change.new_value}
              />
            );
          })}
        </Stack>
      </Stack>
    </Group>
  );
};
